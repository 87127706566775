import { startCase, lowerCase, upperFirst, camelCase } from 'lodash';
import dayjs from 'dayjs';

/**
 * StartCase that don't remove special characters.
 * splits the name on whitespaces, - or _,
 */
export function specialStartCase(name: string): string {
  return name
    ?.replaceAll(/[-_]/g, ' ')
    .split(' ')
    .map((word) => (word[0]?.toUpperCase() ?? '') + word.slice(1))
    .join(' ')
    .trim();
}

export const makeOptions = (
  options: string[] | number[] | PlainObject<string>,
  capitalize = false,
): PropPresets.OptionObject[] => {
  if (!options) return [];
  if (Array.isArray(options)) {
    return options.map((option: string | number) => {
      option = String(option);
      return {
        value: option,
        label: capitalize ? startCase(lowerCase(option)) : option,
      };
    });
  }
  return Object.entries(options).map(([key, val]) => ({
    value: key,
    label: capitalize ? startCase(lowerCase(val)) : val,
  }));
};

export const dateTime = (str: string): string => str && dayjs(str).format('YYYY-MM-DD HH:mm:ss');

export function getUserLocale() {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
}

export function count(countNum: number | '-') {
  if (!countNum || countNum === '-') return countNum;
  const locale = getUserLocale();
  if (Number.isInteger(countNum) || !countNum.toFixed) {
    return new Intl.NumberFormat(locale).format(countNum);
  }
  return new Intl.NumberFormat(locale).format(Number(countNum.toFixed(2)));
}

export function getMonthString(short = false) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (short) return months.map((month) => month.slice(0, 3));
  return months;
}

export function pascalCase(str: string): string {
  return upperFirst(camelCase(str));
}

export default {
  specialStartCase,
  makeOptions,
  dateTime,
  count,
  getMonthString,
  getUserLocale,
  pascalCase,
};
