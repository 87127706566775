import React, { Suspense, useCallback } from 'react';
import { startCase } from 'lodash';
import { Layout, Typography, Space, Drawer, Tag } from 'antd';
import { UserRoleEnum } from '../../constatnts/Enums';
import { appRoutes, MapRoutes, MapRoutesProps, useRightDrawer, drawerMap } from '../../router';
import { Loading } from '../../components';
import { store } from '../../store';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import './styles.less';

function Main() {
  const useDrawerData: UseDrawerReturnType = useRightDrawer();
  const { auth }: { auth: any } = store.getState();
  let sidebarRoutes: RouteItem[] = [];
  let mapRoutes: RouteItem[] = [];
  if (auth.role !== UserRoleEnum.SUPERADMIN) {
    sidebarRoutes = appRoutes.filter(
      (route) => route.name !== 'Project Groups' && route.name !== 'Reset Password',
    );
    mapRoutes = appRoutes.filter((route) => route.name !== 'Project Groups');

    sidebarRoutes = sidebarRoutes.map((route) => {
      if (route.name === 'Users') {
        return {
          ...route,
          buttons: [],
        };
      }
      return route;
    });
    mapRoutes = mapRoutes.map((route) => {
      if (route.name === 'Users') {
        return {
          ...route,
          buttons: [],
        };
      }
      return route;
    });
  }
  else {
    sidebarRoutes = appRoutes.filter((route) => route.name !== 'Reset Password');
    mapRoutes = appRoutes;
  }
  const getHeader = useCallback(
    (
      title: string | JSX.Element,
      buttons: TitleButtonType[] = [],
      btnProps: TitleButtonProps,
      id?: string,
    ) => (
      <>
        <div className="header-left">
          <Typography.Title className="title" level={3}>
            {title}
          </Typography.Title>
          {id ? <Tag color="blue">{id}</Tag> : null}
        </div>
        {buttons?.length ? (
          <Space className="title-btns" size="middle">
            {buttons.map((ButtonComponent, index) => (
              <ButtonComponent key={`${index}`} {...btnProps} />
            ))}
          </Space>
        ) : null}
      </>
    ),
    [],
  );

  const getRouteComponent = useCallback<MapRoutesProps['render']>(
    (props) => {
      const { route, routeProps } = props;
      const { component: Component } = route;

      return (
        <>
          <Layout.Header className="title-bar">
            {getHeader(route.name, route.buttons, { ...routeProps, ...useDrawerData })}
          </Layout.Header>
          <Layout.Content>
            <Component route={route} {...routeProps} {...useDrawerData} />
          </Layout.Content>
        </>
      );
    },
    [getHeader, useDrawerData],
  );

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Layout.Sider collapsible>
          <Sidebar routes={sidebarRoutes} />
        </Layout.Sider>
        <Layout className="main">
          <Layout.Header className="navbar">
            <Navbar />
          </Layout.Header>
          <Suspense fallback={<Loading size="large" />}>
            <MapRoutes routes={mapRoutes} render={getRouteComponent} />
          </Suspense>
        </Layout>
      </Layout>

      {useDrawerData.drawers.map((drawer, index) => {
        const drawerChild = drawerMap[drawer.type];
        const { component: DrawerChildComponent } = drawerChild;

        return (
          <Drawer
            key={`${drawer.type}_${drawer.id}_${index}`}
            title={getHeader(
              drawerChild.title || `${drawer.id ? 'Edit' : 'Add'} ${startCase(drawer.type)}`,
              drawerChild.buttons,
              useDrawerData,
              drawer.id,
            )}
            maskClosable={false}
            closable={false}
            push={false}
            visible={drawer.visible}
            width={1000 - 24 * index}
          >
            <Suspense fallback={<Loading size="large" />}>
              <DrawerChildComponent id={drawer.id} {...useDrawerData} initialData={drawer.data} />
            </Suspense>
          </Drawer>
        );
      })}
    </>
  );
}

export default Main;
