import React, { useCallback, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Form, DatePicker, FormItemProps } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';
import { transformRules, Rule } from '../utils/rules';
import InfoTooltip from './InfoToolTip';

const { RangePicker } = DatePicker;

interface CommonDateProps {
  name: FormItemProps['name'];
  label: string;
  tooltip?: PropPresets.Tooltip;
  disabled?: boolean;
  rules?: Rule[];
  picker?: 'date' | 'year';
  formItemProps?: FormItemProps;
}

export interface FormDatePickerProps extends CommonDateProps {
  type?: 'date';
  placeholder?: string;
  datePickerProps?: DatePickerProps;
  initialValue?: Dayjs;
}

export interface FormRangePickerProps extends CommonDateProps {
  type: 'range';
  placeholder?: [string, string];
  datePickerProps?: RangePickerProps;
  initialValue?: [Dayjs, Dayjs];
}

export type FormDateProps = FormDatePickerProps | FormRangePickerProps;

function FormDate(props: FormDateProps) {
  const {
    label,
    name,
    formItemProps,
    initialValue,
    tooltip,
    placeholder,
    disabled = false,
    type = 'date',
    picker = 'date',
    datePickerProps,
    rules: propRules,
  } = props;
  const rules = useMemo(() => {
    const newRules = transformRules(propRules, {
      type: type === 'range' ? 'array' : 'object',
    });
    return newRules;
  }, [propRules, type]);
  const today = dayjs();
  const defaultInitialValue = [
    today.startOf('day'),
    today.endOf('day')
  ];


  const getPopupContainer = useCallback(
    (node) => node.closest('.ant-card') || node.closest('.ant-form-item'),
    [],
  );

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      tooltip={InfoTooltip.Config(tooltip)}
      initialValue={initialValue ?? defaultInitialValue}
      validateFirst
      getValueFromEvent={(v: Dayjs | [Dayjs, Dayjs] | null) => {
        if (!v) return null;
        if (Array.isArray(v)) {
          return [v[0].startOf('day'), v[1].endOf('day')];
        }
        return v.startOf('day');
      }}
      {...formItemProps}
    >
      {type === 'range' ? (
        <RangePicker
          picker={picker}
          placeholder={placeholder as [string, string]}
          disabled={[disabled, disabled]}
          style={{ width: '100%' }}
          getPopupContainer={getPopupContainer}
          {...(datePickerProps as RangePickerProps)}
        />
      ) : (
        <DatePicker
          picker={picker}
          placeholder={placeholder as string}
          disabled={disabled}
          style={{ width: '100%' }}
          getPopupContainer={getPopupContainer}
          {...(datePickerProps as DatePickerProps)}
        />
      )}
    </Form.Item>
  );
}

export default FormDate;
