import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
} from 'react';
import { Table, TableProps, TableColumnType, PaginationProps } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { castArray, get, isEmpty, merge } from 'lodash';
import { withDefault, NumberParam, StringParam, useQueryParams, JsonParam } from 'use-query-params';
import { Variables } from '../../api';
import Filters, { FiltersMap, FiltersProps } from '../Filters';
import ResizeableTitle, { ResizeableTitleProps } from './ResizeableTitle';
import './styles.scoped.less';

const DEFAULT_PAGE_SIZE = 25;

type RecordType = PlainObject<string | number>;

export interface DataTableRenderFields {
  data: any[];
  loading?: boolean;
  pagination?: PaginationProps;
}

interface DataTableProps {
  api: any;
  rowKey?: string;
  dataKey?: string;
  columns: TableColumnType<RecordType>[];
  transformData?: <T>(data: T) => T;
  onData?: (data: any) => void;
  // onError?: (error: ApolloError) => void;
  apiVariables?: Variables;
  filtersMap?: FiltersMap;
  filtersProps?: Pick<FiltersProps, 'updateQuery' | 'cardProps'>;
  autoSubmit?: boolean;
  onFiltersSubmit?: (apiVariables: PlainObject<any>) => void;
  onFiltersReset?: () => void;
  tableProps?: TableProps<RecordType>;
  render?: (fields: DataTableRenderFields) => JSX.Element;
  useQuery?: boolean;
  expandedRowRender?: (record: any) => JSX.Element;
  rowExpandable?: (record: any) => boolean,
  showDownload?: boolean,
}

const components = {
  header: {
    cell: ResizeableTitle,
  },
};

export const queryParams = {
  page: NumberParam,
  pageSize: withDefault(NumberParam, DEFAULT_PAGE_SIZE, true),
  sortBy: StringParam,
  sortOrder: StringParam,
  filters: JsonParam,
};

export type RefObject = { reload: () => void };

function DataTable(props: DataTableProps, ref: ForwardedRef<RefObject>) {
  const {
    api: useapi,
    rowKey = '_id',
    dataKey = 'data.result',
    columns: propColumns,
    tableProps,
    onData,
    // onError,
    transformData,
    apiVariables: propApiVariables,
    useQuery = true,
    filtersMap,
    filtersProps,
    autoSubmit = true,
    onFiltersSubmit,
    onFiltersReset,
    render,
    expandedRowRender,
    rowExpandable,
    showDownload = false
  } = props;

  const [query, setQuery] = useQueryParams(queryParams);
  const [tableData, setTableData] = useState({ data: [], total: 0 });
  const [tableVariables, setTableVariables] = useState({
    page: query.page,
    limit: query.pageSize,
    sortBy: query.sortBy,
    sortOrder: query.sortOrder,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [apiVariables, setApiVariables] = useState(propApiVariables || {});
  const pageSizeChange = useRef(false);
  const initialMount = useRef(true);
  const hasFilters = !isEmpty(filtersMap);

  const transformedColumns: TableColumnType<RecordType>[] = useMemo(
    () =>
      propColumns.map(
        (
          {
            dataIndex,
            key = castArray(dataIndex).join('_'),
            width = 100,
            align = 'center',
            ...rest
          },
          index,
        ) => ({
          dataIndex,
          key,
          align,
          defaultSortOrder:
            tableVariables.sortBy === dataIndex
              ? ((tableVariables.sortOrder || 'descend') as SortOrder)
              : undefined,
          width,
          ellipsis: !(rest.title === 'Actions' || rest.fixed) && { showTitle: false },
          ...rest,
          onHeaderCell: (column: TableColumnType<RecordType>): ResizeableTitleProps => {
            column.ellipsis = false;
            return {
              width:
                (typeof column.width === 'string'
                  ? Number(column.width.match(/^\d+/)?.[0])
                  : column.width) || 100,
              onResize: (e, { size }) => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                setColumns((preCols) => {
                  const nextCols = [...preCols];
                  nextCols[index] = {
                    ...nextCols[index],
                    width: size.width,
                  };
                  return nextCols;
                });
              },
            };
          },
        }),
      ),
    [propColumns, tableVariables.sortBy, tableVariables.sortOrder],
  );

  const [columns, setColumns] = useState(transformedColumns);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    useapi({
      params: {
        ...tableVariables,
        sortOrder: tableVariables.sortOrder === 'ascend' ? 'ASC' : 'DESC',
        ...apiVariables,
      },
    })
      .then((apiData: any) => {
        onData?.(apiData);
        const items = get(apiData, dataKey);
        const data = transformData?.(items) ?? items;
        const totalKey = dataKey.split('.').slice(0, -1).concat(['totalCount']).join('.');
        const total = get(apiData, totalKey) || data.length;
        setTableData({ total, data });
        setLoading(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err: any) => {
        //  onError?.(err)
      });
  }, [useapi, tableVariables, apiVariables, onData, dataKey, transformData /* onError */]);

  const handlePageChange = useCallback(
    (page?: number) => {
      // don't run page change if page size is changed
      if (pageSizeChange.current) {
        pageSizeChange.current = false;
        return;
      }
      if (page === 1) page = undefined;
      if (useQuery) setQuery({ page }, 'pushIn');
      setTableVariables((prevVars) => ({ ...prevVars, page }));
    },
    [useQuery, setQuery],
  );

  const handlePageSizeChange = useCallback(
    (page: number, pageSize: number) => {
      pageSizeChange.current = true;
      if (useQuery) setQuery({ page: undefined, pageSize }, 'pushIn');
      setTableVariables((prevVars) => ({ ...prevVars, page: undefined, limit: pageSize }));
    },
    [useQuery, setQuery],
  );

  /** Only handles sorting */
  const handleChange = useCallback<NonNullable<TableProps<RecordType>['onChange']>>(
    (pagination, filters, sorter) => {
      const [sort] = castArray(sorter);
      const field = castArray(sort.field).join('.') || undefined;
      if (tableVariables.sortBy === field && tableVariables.sortOrder === sort.order) {
        return;
      }
      const newData = {
        sortBy: sort.order === undefined ? undefined : field,
        sortOrder: sort.order,
        page: undefined,
      };
      if (useQuery) setQuery(newData, 'pushIn');
      setTableVariables((prevVars) => ({ ...prevVars, ...newData }));
    },
    [tableVariables.sortBy, tableVariables.sortOrder, useQuery, setQuery],
  );

  const handleSubmit = useCallback(
    (data: PlainObject<any>) => {
      onFiltersSubmit?.(data);
      setTableVariables((prevVars) =>
        prevVars.page && prevVars.page > 1 ? { ...prevVars, page: undefined } : prevVars,
      );
      setApiVariables(merge({}, propApiVariables, data));
    },
    [onFiltersSubmit, propApiVariables],
  );

  useEffect(() => {
    if (!hasFilters) {
      if (autoSubmit && initialMount.current) {
        fetchData();
      }
    }
    // if filter exists then run on updates only
    else if (!initialMount.current) {
      fetchData();
    }
    if (initialMount.current) initialMount.current = false;
  }, [fetchData, hasFilters, autoSubmit]);

  useImperativeHandle(ref, () => ({
    reload: () => fetchData(),
  }));
  return (
    <>
      {filtersMap ? (
        <Filters
          filtersMap={filtersMap}
          loading={loading}
          onValidSubmit={handleSubmit}
          onReset={onFiltersReset}
          autoSubmit={autoSubmit}
          showDownload={showDownload}
          tableData={tableData.data}
          {...filtersProps}
        />
      ) : null}

      {render ? (
        render({
          data: tableData.data,
          loading: true,
          pagination: {
            defaultPageSize: tableVariables.limit,
            pageSizeOptions: ['25', '50', '75', '100'],
            showQuickJumper: Boolean(tableData.total),
            showSizeChanger: true,
            onChange: handlePageChange,
            onShowSizeChange: handlePageSizeChange,
            current: tableVariables.page || 1,
            total: tableData.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Items`,
          },
        })
      ) : (
        <Table
          className="data-table"
          components={components}
          dataSource={tableData.data}
          rowKey={rowKey}
          columns={columns}
          loading={loading}
          tableLayout="fixed"
          bordered
          size="small"
          expandable={{
            expandedRowRender,
            rowExpandable
          }}
          scroll={{
            x: 'max-content',
            scrollToFirstRowOnChange: true,
          }}
          onChange={handleChange}
          pagination={{
            defaultPageSize: tableVariables.limit,
            pageSizeOptions: ['25', '50', '75', '100'],
            showQuickJumper: Boolean(tableData.total),
            showSizeChanger: true,
            onChange: handlePageChange,
            onShowSizeChange: handlePageSizeChange,
            current: tableVariables.page || 1,
            total: tableData.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Items`,
          }}
          {...tableProps}
        />
      )}
    </>
  );
}

export default forwardRef(DataTable);
