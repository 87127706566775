import React from 'react';
import { Button as AntButton } from 'antd';
import { EyeOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import type { LinkProps } from 'react-router-dom';
import type { ButtonProps as AntButtonProps } from 'antd/lib/button';

export interface ButtonProps extends Omit<AntButtonProps, 'href'> {
  href?: LinkProps['to'];
}

function Button(props: AntButtonProps) {
  return <AntButton {...props} />;
}

export type CustomButtonProps = Omit<ButtonProps, 'type' | 'icon'>;

Button.Edit = (props: AntButtonProps) => (
  <Button className="edit-button" type="primary" icon={<EditOutlined />} {...props} />
);

Button.View = (props: AntButtonProps) => (
  <Button className="view-button" type="primary" icon={<EyeOutlined />} {...props} />
);

Button.Create = (props: AntButtonProps) => (
  <Button className="create-button" type="primary" icon={<PlusOutlined />} {...props} />
);

Button.Delete = (props: AntButtonProps) => (
  <Button className="delete-button" type="primary" danger icon={<DeleteOutlined />} {...props} />
);

export default Button;
