import React, { Suspense } from 'react';
import { Layout } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { authRoutes, MapRoutes, MapRoutesProps } from '../../router';
import { Loading } from '../../components';
import { ReactComponent as LogoSvg } from '../../icons/logo.svg';
import './styles.scoped.less';

function Auth() {
  const getRouteComponent: MapRoutesProps['render'] = ({ route, routeProps }) => {
    const { component: Component } = route;
    // @ts-ignore
    return <Component route={route} {...routeProps} />;
  };
  return (
    <>
      <Layout className="main">
        <Link to="/" className="logo-link">
          <LogoSvg />
        </Link>

        <Suspense fallback={<Loading size="large" />}>
          <MapRoutes
            routes={authRoutes}
            render={getRouteComponent}
            default={() => <Redirect to="/login" />}
          />
        </Suspense>
      </Layout>
    </>
  );
}

export default Auth;
