import { isPlainObject, isArray, isNil, isEqual, isObject, mapValues } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import filterTypes, { FiltersMap } from './types';
import type { UseFiltersQuery, FiltersFormData } from './Filters';

const defaultValues = mapValues(filterTypes, 'default');

function getValue(valObj: any) {
  return isPlainObject(valObj) ? valObj.value : valObj;
}

function isSameDate(d1: Date, d2: Date) {
  return dayjs(d1).isSame(dayjs(d2), 'date');
}

function convertToQP(
  setQuery: UseFiltersQuery['setQuery'],
  data: FiltersFormData,
  oldQuery: UseFiltersQuery['query'],
  { defaults = false, updateQuery = true } = {},
) {
  const query: UseFiltersQuery['query'] = {
    filters: {},
    fromDate: undefined,
    toDate: undefined,
    phrase: undefined,
  };

  const apiVariables: {
    fromDate?: string;
    toDate?: string;
    phrase?: string;
  } & PlainObject<unknown> = {};

  const { dateRange, phrase, filters = {} } = data;
  const [fromMoment, toMoment] = dateRange ?? [];
  if (fromMoment && toMoment) {
    query.fromDate = fromMoment.toDate();
    query.toDate = toMoment.toDate();

    const fromDate = fromMoment.format();
    const toDate = toMoment.format();
    apiVariables.fromDate = fromDate;
    apiVariables.toDate = toDate;
  }

  if (phrase) {
    apiVariables.phrase = phrase;
    query.phrase = phrase;
  }

  const { filters: oldFilters = {} } = oldQuery;
  let updateFiltersQP = false;

  Object.entries(filters).forEach(([key, val]) => {
    let value = getValue(val);
    if (isNil(value)) {
      if (oldFilters[key] !== undefined) {
        // undefined is needed to delete QP
        query.filters[key] = undefined;
        apiVariables[key] = undefined;
        updateFiltersQP = true;
      }
      return;
    }

    if (isArray(value)) {
      value = value.map(getValue).filter(Boolean);

      if (!value.length) {
        if (oldFilters[key] !== undefined) {
          query.filters[key] = undefined;
          apiVariables[key] = undefined;
          updateFiltersQP = true;
        }
        return;
      }
    }

    apiVariables[key] = value;
    query.filters[key] = value;
    if (!isEqual(oldFilters[key], value)) updateFiltersQP = true;
  });

  if (Object.values(query.filters).filter((v) => !isNil(v)).length === 0) {
    query.filters = undefined;
  }

  if (
    updateQuery &&
    (updateFiltersQP ||
      (query.fromDate && oldQuery.fromDate && !isSameDate(query.fromDate, oldQuery.fromDate)) ||
      (query.toDate && oldQuery.toDate && !isSameDate(query.toDate, oldQuery.toDate)) ||
      query.phrase !== oldQuery.phrase)
  ) {
    // Using "In" variants as we don't want to reset page and other query params of other components
    setQuery(query, defaults ? 'replaceIn' : 'pushIn');
  }
  return { apiVariables };
}

function convertFromQP(query: UseFiltersQuery['query'], filtersMap: FiltersMap) {
  const { fromDate, toDate, phrase, filters = {} } = query;

  let fromMoment: Dayjs | null = null;
  let toMoment: Dayjs | null = null;

  if (fromDate) fromMoment = dayjs(fromDate).startOf('day');
  else if (isObject(filtersMap.date) && filtersMap.date.required) {
    fromMoment = dayjs().startOf('day');
    if (filtersMap.date.oldFromDate) {
      fromMoment = dayjs().startOf('day').subtract(1, 'year');
    }
  }

  if (toDate) toMoment = dayjs(toDate).endOf('day');
  else if (isObject(filtersMap.date) && filtersMap.date.required) {
    toMoment = dayjs().endOf('day');
  }

  const data: { filters: PlainObject<unknown>; dateRange?: [Dayjs, Dayjs]; phrase?: string } = {
    filters: {},
    ...(fromMoment && toMoment && { dateRange: [fromMoment, toMoment] }),
    ...(filtersMap.phrase && phrase && { phrase }),
  };

  Object.entries(filtersMap).forEach(([key, val]) => {
    if (['date', 'phrase'].includes(key) || !val) return;
    const path = isObject(val) && 'name' in val && val.name ? val.name : key;
    if (filters[path]) {
      data.filters[path] = filters[path];
    }
    else {
      data.filters[path] =
        isObject(val) && 'initialValue' in val && val.initialValue
          ? val.initialValue
          : defaultValues[key];
    }
  });
  return data;
}

export { convertToQP, convertFromQP };
