import React, { useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Menu } from 'antd';
import { castArray } from 'lodash';
import { appRoutes, IterateRoutes, MapRoutes } from '../../router';
import { ReactComponent as LogoSvg } from '../../icons/logo.svg';
import { ReactComponent as WhatsappSvg } from '../../icons/Whatsappify.svg';

function Sidebar({ routes }: { routes: RouteItem[] }) {
  const getMenuLabel = ({ icon, name }: RouteItem) => (
    <>
      {icon}
      <span>{name}</span>
    </>
  );

  const items = IterateRoutes({
    routes,
    renderParent: ({ route, key, children }) => {
      if (route.invisible) return null;

      const filtered = children.filter(Boolean);
      return (
        <Menu.SubMenu key={key} title={getMenuLabel(route)}>
          {filtered}
        </Menu.SubMenu>
      );
    },
    render: ({ route, key, parent }) => {
      if (route.invisible || parent?.invisible) return null;
      return (
        <Menu.Item key={key}>
          <Link to={generatePath(castArray(route.path)[0])}>{getMenuLabel(route)}</Link>
        </Menu.Item>
      );
    },
  });

  useEffect(() => {
    const panelType = localStorage.getItem('panelType');
    if (panelType === '1') {
      // appRoutes[4]['views'].push()
      document.body.classList.add('dark');
    }
    else {
      document.body.classList.remove('dark');
    }
  })
  return (
    <>
      <Link to="/dashboard" className="sidebar-logo">
        {localStorage.getItem('panelType') === '1' ? (
          <WhatsappSvg />
        ) : (
          <LogoSvg />
        )}
      </Link>
      <MapRoutes
        routes={appRoutes}
        default={() => (
          <Menu mode="inline" theme="dark">
            {items}
          </Menu>
        )}
        render={({ parent, key }) => (
          <Menu
            mode="inline"
            theme="dark"
            defaultOpenKeys={parent ? [parent.key] : []}
            defaultSelectedKeys={[key]}
          >
            {items}
          </Menu>
        )}
      />
    </>
  );
}

export default Sidebar;
