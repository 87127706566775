const authReducer = (state = {}, action: any) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      // logger.debug('Login Success');
      return {
        ...state,
        accessToken: action.accessToken,
        userName: action.userName,
        role: action.role,
        id: action.id,
        firstLogin: action.firstLogin,
      };

    case 'LOGOUT':
      // logger.debug('LOGOUT');
      return {
        ...state,
        accessToken: null,
        userName: null,
      };

    case 'UPDATE_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.accessToken,
      };

    default:
      return state;
  }
};

export default authReducer;
