import Axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import { logout, updateAccessToken } from '../store/actions/authAction';
import { store } from '../store';

const { protocol } = window.location;
const reactBaseApi = process.env.REACT_APP_BASE_API;

const BASE_API = `${protocol}//${reactBaseApi}`;

let axiosInstance: AxiosInstance;

function getAxios() {
  if (axiosInstance) return axiosInstance;

  axiosInstance = Axios.create({
    baseURL: `${BASE_API}`,
    timeout: 30000,
    withCredentials: false,
    headers: {
      'X-Requested-With': `Textify Frontend ${process.env.REACT_APP_VERSION}`,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  // FIXME: Find better way to do this try:
  // https://github.com/axios/axios/issues/209
  axiosInstance.interceptors.request.use((config) => {
    if (config.headers.Authorization) {
      return config;
    }
    const { auth }: { auth: any } = store.getState();
    if (auth.accessToken) {
      config.headers.Authorization = `Bearer ${auth.accessToken}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      const { auth }: { auth: any } = store.getState();
      if (auth.accessToken && 'access_token' in response.data) {
        store.dispatch(updateAccessToken(response.data.accessToken));
      }
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        store.dispatch(logout);
      }
      // Throw err again (may be need for some other catch)
      return Promise.reject(error);
    },
  );
  return axiosInstance;
}

export default getAxios();

export { getAxios };
