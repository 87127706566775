import React from 'react';
import { castArray, isNil } from 'lodash';
import { Typography } from 'antd';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import FaIcon from '../FaIcon';
import './styles.scoped.less';

const { Text } = Typography;

type CopyItemProp =
  | string
  | number
  | {
      text?: string | number;
      className?: string;
      tooltips?: string | string[];
      children?: import('react').ReactElement;
    };
type CopyItemProps = CopyItemProp &
  Omit<import('antd/lib/typography').TypographyProps['Text'], 'copyable'>;

function CopyItem(props: CopyItemProps = {}) {
  /** @type {number | string} */
  let text;
  let tooltips;
  let children;
  let rest = {};

  if (typeof props === 'string' || typeof props === 'number') text = props;
  else {
    ({ text, tooltips, children, ...rest } = props);
  }

  if (isNil(text)) return <>-</>;
  if (!children) children = text;

  tooltips = tooltips ? castArray(tooltips) : ['Click to copy ID'];

  return (
    <Text
      copyable={{
        text: String(text),
        tooltips,
        icon: <FaIcon icon={faCopy} />,
      }}
      {...rest}
    >
      {children}
    </Text>
  );
}

export default CopyItem;
