import React from 'react';
import { startCase } from 'lodash';
import { Tag, TagProps } from 'antd';

type StatusBadgeProps =
  | string
  | number
  | {
      val: string;
      startCase?: boolean;
      color?: TagProps['color'];
    };

const statusColor: { [key: string]: string } = {
  ACTIVE: 'success',
  INACTIVE: 'error',
  BANNED: 'warning',

  ENABLED: 'success',
  PAUSED: 'warning',
  DISABLED: 'error',

  RESOLVED: 'success',

  SUPERADMIN: 'success',
  ADMIN: 'warning',
};

/**
 * NOTE: Not exactly a component as it also works as a function directly
 */
function StatusBadge(props: StatusBadgeProps) {
  let val: string;
  let color: TagProps['color'] | undefined;
  let convertToStartCase: boolean | undefined = true;
  let rest: TagProps = {};

  if (!props || typeof props === 'string' || typeof props === 'number') val = String(props ?? '-');
  else {
    ({ val, startCase: convertToStartCase, color, ...rest } = props);
  }

  return (
    <Tag color={color || statusColor[String(val)] || 'default'} {...rest}>
      {convertToStartCase ? startCase(String(val)) : val}
    </Tag>
  );
}

export default StatusBadge;
