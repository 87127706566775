import { FormInstance, notification } from 'antd';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { toPath } from 'lodash';
import { logger } from '../utils';

const { isCancel } = Axios;
type HandlerOptions = { notification?: string | boolean };

const ERROR_MSG: any = {
  400: 'Validation Error',
  404: 'Page not found',
  403: 'Incorrect entry',
  418: 'Nothing to change',
  401: 'Unauthorised',
  500: 'Server Error',
};

const UNKOWN = 'Unknown Error (API)';

function getErrorMessage(error: any): string {
  if (!error) return UNKOWN;
  if (!error.response) return error.message || UNKOWN;
  const { response } = error;
  if (response.data) {
    if (response.data.errors) {
      return response.data.errors.message;
    }
    if (response.data.message) {
      return response.data.message;
    }
  }
  if (response.message) {
    return response.message;
  }
  return ERROR_MSG[response.status];
}

function basicSuccessHandler(
  res: AxiosResponse,
  { notification: notificationMsg = false }: HandlerOptions = {},
) {
  if (!notificationMsg) return res;
  const description = res.data.message;
  notification.success({
    message: typeof notificationMsg === 'string' ? notificationMsg : 'Success',
    description,
  });
  return res;
}

function basicErrorHandler(
  error: AxiosError,
  { notification: notificationMsg = true }: HandlerOptions = {},
) {
  // Request manually cancelled using CancelToken
  if (isCancel(error)) return;
  const description = getErrorMessage(error);
  if (notificationMsg) {
    notification.error({
      message: typeof notificationMsg === 'string' ? notificationMsg : 'Failure',
      description,
    });
  }
}

function convertToIndex(path: string) {
  const index = Number(path);
  if (Number.isNaN(index)) return path;
  return index;
}

function getFormErrorsFromError(error: AxiosError) {
  const errors = error?.response?.data?.errors;
  if (errors?.response?.statusCode) return []; // for Exceptions thrown from Backend
  if (!errors) {
    logger.error({ err: error }, 'NON_FORM_ERROR');
    return [];
  }
  return Object.keys(errors).map((path) => ({
    errors: [errors[path]],
    name: toPath(path).map(convertToIndex),
  }));
}

function setFormErrors(error: AxiosError, formRef: FormInstance) {
  if (isCancel(error)) return;
  const fieldData = getFormErrorsFromError(error);
  if (fieldData.length) {
    fieldData[0].errors[0] = fieldData[0].errors[0].replace('whatsapp_', '');
    formRef.setFields(fieldData);
    formRef.scrollToField(fieldData[0].name);
    // @ts-ignore
    error.fieldData = fieldData;
  }
}

export { basicSuccessHandler, basicErrorHandler, getFormErrorsFromError, setFormErrors };
