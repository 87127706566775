const login =
  (token: string, userName: string, role: string, id: string, firstLogin: string) =>
    (dispatch: any) => {
      dispatch({
        type: 'LOGIN_SUCCESS',
        accessToken: token,
        userName,
        role,
        id,
        firstLogin,
      });
    };

const logout = { type: 'LOGOUT' };

const updateAccessToken = (token: string) => (dispatch: any) => {
  if (!token) return;

  dispatch({ type: 'UPDATE_ACCESS_TOKEN', accessToken: token });
};

export { login, logout, updateAccessToken };
