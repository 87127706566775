import React, { ComponentType } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { flatten, omit } from 'lodash';

export interface RouteItemCommon {
  name: string;
  path: string | string[];
  icon?: JSX.Element;
  permissions?: string[];
  invisible?: boolean;
}

export interface RouteItemChild extends RouteItemCommon {
  component: ComponentType<MainContentProps>;
  buttons?: TitleButtonType[];
  exact?: boolean;
}

export interface RouteItemParent extends RouteItemCommon {
  views: RouteItemChild[];
}

interface RouteItemParentWithKey extends RouteItemParent {
  key: string;
}

function getFullPath(route: RouteItemChild, parent: RouteItemParent) {
  const basePath = parent.path || '';
  if (!Array.isArray(route.path)) {
    return `${basePath}${route.path}`;
  }
  return route.path.map((path) => `${basePath}${path}`);
}

export type IterateRoutesProps = {
  routes: RouteItem[];
  default?: JSX.Element;
  render: (props: {
    key: string;
    route: RouteItemChild;
    parent?: RouteItemParentWithKey;
  }) => JSX.Element | null;
  renderParent?: (props: {
    key: string;
    route: RouteItemParent;
    children: JSX.Element[];
  }) => JSX.Element | null;
};

function IterateRoutes(props: IterateRoutesProps) {
  function recurseFunction(
    routes: RouteItem[],
    parent?: RouteItemParentWithKey,
  ): (JSX.Element | null)[] {
    return flatten(
      routes.map((route, index) => {
        const key = parent ? `${parent.key}.${index}` : `${index}`;

        if ('views' in route) {
          const children = recurseFunction(route.views, { ...route, key }).filter(Boolean);
          if (props.renderParent) {
            return props.renderParent({
              children: children.filter(Boolean) as JSX.Element[],
              route,
              key,
            });
          }
          return children;
        }

        return props.render({
          key,
          parent,
          route: {
            ...route,
            path: getFullPath(route, omit(parent, 'key')),
          },
        });
      }),
    );
  }

  const items = recurseFunction(props.routes).filter(Boolean);
  if (props.default) {
    items.push(props.default);
  }
  return items;
}

export type MapRoutesProps = {
  routes: RouteItem[];
  render: (props: {
    parent?: RouteItemParentWithKey;
    route: RouteItemChild;
    key: string;
    routeProps: RouteComponentProps;
  }) => JSX.Element;
  default?: (props: RouteComponentProps) => JSX.Element;
};

function MapRoutes(props: MapRoutesProps) {
  const items = IterateRoutes({
    routes: props.routes,
    render: ({ route, key, parent }) => (
      <Route
        path={route.path}
        key={key}
        exact={route.exact}
        render={(routeProps) => props.render?.({ parent, key, route, routeProps })}
      />
    ),
    default: props.default && <Route path="*" key="-1" render={props.default} />,
  });
  return <Switch>{items}</Switch>;
}

export { getFullPath, IterateRoutes, MapRoutes };
