import React from 'react';
import { Row, Col, Card, Result } from 'antd';

/**
 * @typedef {import('prop-types').InferProps<typeof ResultCard.propTypes>} Props
 *
 * @extends {PureComponent<Props>}
 */
type ResultCardProps = {
  status?: 'success' | 'error' | 'info' | 'warning';
  title: string;
  subTitle: string;
  extra: JSX.Element;
  rowProps?: any;
};
export default function ResultCard(props: ResultCardProps) {
  const { title, subTitle, extra, status = 'success', rowProps } = props;

  return (
    <Row justify="center" {...rowProps}>
      <Col xs={24} sm={24} md={10}>
        <Card>
          <Result status={status} title={title} subTitle={subTitle} extra={extra} />
        </Card>
      </Col>
    </Row>
  );
}
