import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import authReducer from './authReducer';

const storage = localforage.createInstance({
  name: 'sms_store',
  version: 1,
});

const authPersistConfig = {
  key: 'auth',
  storage,
  version: 1,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
});

export default rootReducer;
