import React from 'react';
import { Card, Statistic } from 'antd';
import './styles.scoped.less';

interface StatsCardProps {
  title: string;
  value: any;
  suffix?: string;
  precision?: number;
}

function StatsCard(props: StatsCardProps) {
  const { title, value, suffix, precision } = props;
  return (
    <Card headStyle={{ textAlign: 'center' }}>
      <Statistic
        title={title}
        value={value}
        style={{ textAlign: 'center', width: '100%' }}
        suffix={suffix}
        precision={precision}
      />
    </Card>
  );
}
export default StatsCard;
