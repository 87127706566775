import React, { useMemo } from 'react';
import { Form, Input, FormItemProps, InputProps as AntInputProps } from 'antd';
import { MailOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons';
import { noWhitespace, Rule } from '../utils/rules';
import InfoTooltip from './InfoToolTip';

interface InputProps {
  name: FormItemProps['name'];
  label: string;
  tooltip?: PropPresets.Tooltip;
  placeholder?: string;
  disabled?: boolean;
  rules?: Rule[];
  formItemProps?: FormItemProps;
}

interface CommonInputProps extends InputProps {
  type?: 'text' | 'url' | 'email' | 'tel';
  inputProps?: AntInputProps;
}

interface TextareaProps extends InputProps {
  type: 'textarea';
  inputProps?: import('antd/lib/input').TextAreaProps;
}

interface PasswordProps extends InputProps {
  type: 'password';
  inputProps?: import('antd/lib/input').PasswordProps;
}

export type FormInputProps = CommonInputProps | TextareaProps | PasswordProps;

function FormInput(props: FormInputProps) {
  const {
    name,
    label,
    placeholder: propPlaceholder,
    type = 'text',
    formItemProps,
    inputProps,
    tooltip,
    rules: propRules = [],
    disabled = false,
  } = props;

  const placeholder = propPlaceholder || label;
  const rules = useMemo(() => [...propRules, noWhitespace], [propRules]);

  const child = useMemo(() => {
    switch (type) {
      case 'textarea':
        return (
          <Input.TextArea
            placeholder={placeholder}
            disabled={disabled}
            autoSize={{ minRows: 2, maxRows: 4 }}
            showCount
            {...(inputProps as TextareaProps['inputProps'])}
          />
        );

      case 'email':
        return (
          <Input
            type="email"
            placeholder={placeholder}
            disabled={disabled}
            prefix={<MailOutlined />}
            {...(inputProps as CommonInputProps['inputProps'])}
          />
        );

      case 'tel':
        return (
          <Input
            placeholder={placeholder}
            disabled={disabled}
            prefix={<MobileOutlined />}
            type="tel"
            {...(inputProps as CommonInputProps['inputProps'])}
          />
        );

      case 'password':
        return (
          <Input.Password
            type="password"
            placeholder={placeholder}
            prefix={<LockOutlined />}
            autoComplete="current-password"
            visibilityToggle
            disabled={disabled}
            {...(inputProps as PasswordProps['inputProps'])}
          />
        );

      default:
        return (
          <Input
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            {...(inputProps as CommonInputProps['inputProps'])}
          />
        );
    }
  }, [type, placeholder, disabled, inputProps]);

  return (
    <Form.Item
      hasFeedback={!!propRules.length}
      name={name}
      label={label}
      rules={rules}
      tooltip={InfoTooltip.Config(tooltip)}
      validateFirst
      {...formItemProps}
    >
      {child}
    </Form.Item>
  );
}

export default FormInput;
