import React, { lazy } from 'react';
import {
  faEnvelopeOpen,
  faKey,
  faProjectDiagram,
  faLink,
  faUser,
  faChartBar,
  faBroadcastTower,
  faMailBulk,
  faHistory,
  faMouse,
  faSms,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import { FaIcon, Button } from '../components';
import type { RouteItemChild } from './utils';

const buttonStyle = {
  backgroundColor: localStorage.getItem('panelType') === '1' ? "green" : "",
};
const appRoutes: RouteItem[] = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <FaIcon icon={faChartBar} />,
    component: lazy(
      () => import(/* webpackChunkName Dashboard */ '../views/apps/DashBoard/DashBoard'),
    ),
  },
  {
    name: 'API Keys',
    path: '/api-keys',
    icon: <FaIcon icon={faKey} />,
    component: lazy(
      () => import(/* webpackChunkName: "Api Key" */ '../views/apps/ApiKey/ListApiKey'),
    ),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create style={buttonStyle} onClick={() => openDrawer('APIKey')}>Add API Key</Button.Create>
      ),
    ],
  },
  {
    name: 'Campaigns',
    path: '/campaigns',
    icon: <FaIcon icon={faBullhorn} />,
    component: lazy(
      () => import(/* webpackChunkName: "Campaign" */ '../views/apps/Campaign/ListCampaign'),
    ),
  },
  {
    name: 'Channels',
    path: '/channels',
    icon: <FaIcon icon={faBroadcastTower} />,
    component: lazy(
      () => import(/* webpackChunkName: "Channels" */ '../views/apps/Channel/ListChannel'),
    ),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create style={buttonStyle} onClick={() => openDrawer('Channels')}>Add Channels</Button.Create>
      ),
    ],
  },
  {
    name: 'Logs',
    path: '/logs',
    icon: <FaIcon icon={faHistory} />,
    views: [
      {
        name: 'Click Logs',
        path: '/click-logs',
        icon: <FaIcon icon={faMouse} />,
        component: lazy(
          () => import(/* webpackChunkName Click Logs */ '../views/apps/ClickLogs/ListClickLogs'),
        ),
      },
      {
        name: 'Button Click Logs',
        path: '/button-click-logs',
        icon: <FaIcon icon={faMouse} />,
        component: lazy(
          () => import(/* webpackChunkName Click Logs */ '../views/apps/ButtonClickLogs/ButtonListClickLogs'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create style={buttonStyle} onClick={() => openDrawer('HashCreate')}>Add Hash</Button.Create>
          ),
        ],
      },
      {
        name: 'Sent Message Logs',
        path: '/sent-message-logs',
        icon: <FaIcon icon={faSms} />,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "Sent Message Logs" */ '../views/apps/SentMessageLogs/ListSentMessageLogs'
            ),
        ),
      },
    ],
  },
  {
    name: 'Domains',
    path: '/domains',
    icon: <FaIcon icon={faLink} />,
    component: lazy(() => import(/* webpackChunkName Domain */ '../views/apps/Domain/ListDomain')),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create style={buttonStyle} onClick={() => openDrawer('Domain')}>Add Domain</Button.Create>
      ),
    ],
  },
  {
    name: 'Sender IDS',
    path: '/senders',
    icon: <FaIcon icon={faEnvelopeOpen} />,
    component: lazy(
      () => import(/* webpackChunkName: "Sender IDS" */ '../views/apps/Sender/ListSender'),
    ),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create style={buttonStyle} onClick={() => openDrawer('SenderID')}>Add Sender ID</Button.Create>
      ),
    ],
  },
  {
    name: 'Sender Groups',
    path: '/sender-groups',
    icon: <FaIcon icon={faMailBulk} />,
    component: lazy(
      () =>
        import(/* webpackChunkName: "Sender Groups" */ '../views/apps/SenderGroup/ListSenderGroup'),
    ),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create style={buttonStyle} onClick={() => openDrawer('SenderGroup')}>Add Sender Group</Button.Create>
      ),
    ],
  },
  {
    name: 'Users',
    path: '/users',
    icon: <FaIcon icon={faUser} />,
    component: lazy(() => import(/* webpackChunkName: "User" */ '../views/apps/User/ListUser')),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create style={buttonStyle} onClick={() => openDrawer('User')}>Add User</Button.Create>
      ),
    ],
  },
  {
    name: 'Project Groups',
    path: '/project-groups',
    icon: <FaIcon icon={faProjectDiagram} />,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "Project Group" */ '../views/apps/ProjectGroup/ListProjectGroup'
        ),
    ),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create style={buttonStyle} onClick={() => openDrawer('ProjectGroup')}>Add Project Group</Button.Create>
      ),
    ],
  },
];

const authRoutes: RouteItemChild[] = [
  {
    name: 'Login',
    path: '/login',
    component: lazy(() => import(/* webpackChunkName: "Login" */ '../views/auth/Login')),
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    component: lazy(
      () => import(/* webpackChunkName: "Forgot Password" */ '../views/auth/ForgotPassword'),
    ),
  },
  {
    name: 'Reset Password',
    path: '/reset-password/:token',
    component: lazy(
      () => import(/* webpackChunkName: "Reset Password" */ '../views/auth/ResetPassword'),
    ),
  },
];

export { appRoutes, authRoutes };
