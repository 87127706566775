import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, castArray, uniqBy, isNil, get, omit } from 'lodash';
import { Form, FormItemProps, Select, SelectProps as AntSelectProps, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { logger } from '../utils';
import { getSearchRule, Rule, transformRules } from '../utils/rules';
import { Variables } from '../api';
import InfoTooltip from './InfoToolTip';

type SearchOption = {
  label: string;
  value: string | number;
};

export interface FormSearchProps {
  name: FormItemProps['name'];
  label: string;
  api: any;
  tooltip?: PropPresets.Tooltip;
  type?: 'string' | 'number';
  placeholder?: string;
  disabled?: boolean;
  rules?: Rule[];
  mode?: 'default' | 'multiple';
  searchKey?: string;
  dataKey?: string;
  labelKey?: string;
  onSelectChange?: (selectedOptions: SearchOption[]) => void;
  searchProps?: AntSelectProps<SelectValue>;
  formItemProps?: FormItemProps;
  initialValue?: number | string | number[] | string[];
  apiVariables?: Variables;
}

function FormSearch(props: FormSearchProps) {
  const {
    name,
    label,
    tooltip,
    api: useapi,
    placeholder = label,
    type = 'string',
    disabled = false,
    mode: propMode = 'default',
    searchKey = 'ids',
    dataKey = 'data.result',
    labelKey = 'label',
    rules: propRules,
    initialValue,
    searchProps,
    formItemProps,
    onSelectChange,
    apiVariables,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState<SearchOption[]>([]);
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const lastFetchId = useRef(0);
  const pendingPromise = useRef<Promise<SearchOption[]>>(Promise.resolve([]));

  const allOptions = useMemo(
    () => uniqBy([...selectedOptions, ...options].filter(Boolean), (option) => option.value),
    [selectedOptions, options],
  );

  const rules = useMemo(
    () =>
      transformRules(
        [
          ...(propRules || []),
          getSearchRule(async () => {
            const opts = await pendingPromise.current;
            return [...allOptions, ...opts];
          }),
        ],
        {
          type: propMode === 'multiple' ? 'array' : type,
        },
      ),
    [allOptions, propMode, propRules, type],
  );

  const fetchData = useCallback(
    async (
      val: string | PlainObject<any> = '',
      apiVars?: PlainObject<any>,
    ): Promise<SearchOption[]> => {
      setLoading(true);
      let vars: PlainObject<any> = apiVars
        ? { limit: 20, ...apiVars }
        : { limit: 20, ...apiVariables };
      if (typeof val === 'string') vars.phrase = val;
      else vars = { ...vars, phrase: '', ...val };
      return new Promise((resolve, reject) => {
        useapi({ params: vars })
          .then((apiData: any) => {
            const items: SearchOption[] =
              get(apiData, dataKey).map((data: PlainObject<string>) => {
                const searchLabel: string = get(data, labelKey);
                return { label: `${searchLabel}`, value: data.value };
              }) || [];

            resolve(items);
            setLoading(false);
          })
          .catch(reject);
      });
    },
    [apiVariables, useapi, dataKey, labelKey],
  );

  const addOptions = useRef(
    debounce(async (phrase = '') => {
      lastFetchId.current += 1;
      const fetchId = lastFetchId.current;

      try {
        const result = await fetchData(phrase);
        // Don't set for older calls
        if (fetchId !== lastFetchId.current) return;
        setOptions(result);
      }
      catch (err) {
        logger.error({ err }, 'Form Search Error');
      }
    }, 500),
  ).current;

  // For Edit Case
  useEffect(() => {
    const initVals = castArray(initialValue || []);
    if (isNil(initVals) || !initVals.length) return;

    pendingPromise.current = fetchData(
      { [searchKey]: initVals, limit: initVals.length },
      omit(apiVariables, 'status'),
    )
      .then((result) => {
        const selected = result.filter((item) => initVals.includes(item.value));
        if (selected.length) setSelectedOptions((prevVal) => [...prevVal, ...selected]);
        return selected;
      })
      .catch((err) => {
        logger.error({ err }, 'Error while FormSearch init');
        return [];
      });
  }, [initialValue, searchKey, fetchData, apiVariables]);

  const onSelect: AntSelectProps<SelectValue>['onSelect'] = useCallback(
    (val) => {
      const optionToAdd = options.find((option) => option.value === val);
      if (optionToAdd) {
        setSelectedOptions((prevVals) => {
          const newOptions = propMode === 'multiple' ? [...prevVals, optionToAdd] : [optionToAdd];
          onSelectChange?.(newOptions);
          return newOptions;
        });
      }
    },
    [options, onSelectChange, propMode],
  );

  const onDeselect: AntSelectProps<SelectValue>['onDeselect'] = useCallback(
    (val) => {
      setSelectedOptions((prevVals) => {
        const newOptions = prevVals.filter((option) => option.value !== val);
        onSelectChange?.(newOptions);
        return newOptions;
      });
    },
    [onSelectChange],
  );

  // initialize options on focus
  const onFocus: AntSelectProps<SelectValue>['onFocus'] = useCallback(() => {
    if (!options?.length) addOptions();
  }, [options, addOptions]);

  const onClear: AntSelectProps<SelectValue>['onClear'] = useCallback(() => {
    if (selectedOptions.length) setSelectedOptions([]);
  }, [selectedOptions]);

  const getPopupContainer: AntSelectProps<SelectValue>['getPopupContainer'] = useCallback(
    (node) => node.closest('.ant-card') || node.closest('.ant-form-item'),
    [],
  );

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      rules={rules}
      tooltip={InfoTooltip.Config(tooltip)}
      validateFirst
      initialValue={initialValue}
      {...formItemProps}
    >
      <Select
        showSearch
        // Disable filter as results are already filtered from backend
        filterOption={false}
        allowClear
        mode={propMode !== 'default' ? propMode : undefined}
        tokenSeparators={[',']}
        placeholder={placeholder}
        onFocus={onFocus}
        onSearch={addOptions}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onClear={onClear}
        disabled={disabled}
        optionFilterProp="label"
        getPopupContainer={getPopupContainer}
        {...searchProps}
        notFoundContent={
          loading ? (
            <div style={{ textAlign: 'center', padding: '10px 0' }}>
              <Spin />
            </div>
          ) : undefined
        }
        options={allOptions}
      />
    </Form.Item>
  );
}

export default FormSearch;
