export const Status = ['ACTIVE', 'INACTIVE'];
export const BotStatus = ['Yes', 'No'];
export const ChannelType = ['OTP', 'PROMO'];
export const UserRole = ['SUPERADMIN', 'ADMIN'];
export const SentMessageType = ['OTP', 'PROMO', 'PERSONALISED'];
export const CampaignType = ['PROMO', 'PERSONALISED'];
export const ChannelServiceProvider = ['VALUEFIRST', 'NETCORE', 'EXOTEL', 'AIRTEL', 'WHATSAPP'];
export enum Language {
  ENGLISH = 'en',
}
export enum UserRoleEnum {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
}
export enum CampaignTypeEnum {
  PROMO = 'PROMO',
  PERSONALISED = 'PERSONALISED',
}
export enum SentMessageTypeEnum {
  OTP = 'OTP',
  PROMO = 'PROMO',
  PERSONALISED = 'PERSONALISED',
}
export const alphaNumericRegExp = /^[A-Za-z0-9_-]*$/;
export const PASSWORD_VALIDATOR = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/;
export const DOMAIN_REGEXP = /^((?!-)[A-Za-z0-9]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/;

