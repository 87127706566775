import React from 'react';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { Button, ButtonProps } from 'antd';
import CopyItem from './CopyItem';
import OverflowTooltip from './OverflowToolTip';

interface CellProps {
  title: string | JSX.Element;
  id?: string | number;
  subTitle?: string | JSX.Element;
  brackets?: boolean;
}

function Cell({ title, id, subTitle = !isNil(id) ? String(id) : id, brackets = true }: CellProps) {
  if (!subTitle) return <>{title}</>;

  subTitle = (
    <>
      {brackets ? '(' : null}
      {subTitle}
      {brackets ? ')' : null}
    </>
  );

  return (
    <>
      <span className="row-title">{title}</span>
      <br />
      {id ? (
        <CopyItem text={id} className="row-sub-title">
          {subTitle}
        </CopyItem>
      ) : (
        <div className="row-sub-title">{subTitle}</div>
      )}
    </>
  );
}

Cell.DateTime = (val: string) => {
  if (!val) return null;
  const time = dayjs(val);
  return <Cell title={time.format('YYYY-MM-DD')} subTitle={time.format('HH:mm:ss')} />;
};

Cell.Date = (val: string) => {
  if (!val) return null;
  const time = dayjs(val);
  return <Cell title={time.format('YYYY-MM-DD')} />;
};

interface CellLinkProps {
  title: string;
  id?: CellProps['id'];
  onClick: ButtonProps['onClick'];
}

Cell.Link = ({ title, id, onClick }: CellLinkProps) => (
  <Cell
    id={id}
    title={
      <Button type="link" onClick={onClick}>
        {OverflowTooltip(title)}
      </Button>
    }
  />
);

export default Cell;
