import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { startCase, castArray } from 'lodash';
import { Card, Button, Form, Popconfirm, CardProps } from 'antd';
import { FormListProps, FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import { PlusOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import './styles.scoped.less';
import InfoTooltip from '../InfoToolTip';

interface FormDynamicListProps {
  name: FormListProps['name'];
  title: string;
  pluralTitle?: string;
  tooltip?: PropPresets.Tooltip;
  render: (field: FormListFieldData) => JSX.Element;
  loading?: boolean;
  min?: number;
  max?: number;
  defaultValue?: any;
  allowDelete?: boolean;
  inline?: boolean;
  allowAdd?: boolean;
  postAdd?: () => void;
  cardProps?: Omit<CardProps, 'title' | 'size' | 'className'>;
  deleteBtnStyle?: React.CSSProperties;
}

function FormDynamicList(props: FormDynamicListProps) {
  const {
    name,
    title,
    pluralTitle = title,
    tooltip,
    render,
    loading = false,
    min = 0,
    max = 20,
    defaultValue,
    allowDelete = true,
    inline = false,
    postAdd,
    cardProps,
    deleteBtnStyle,
    allowAdd = true
  } = props;

  useEffect(() => {
    // Don't allow to delete initial data in edit case
    if (!allowDelete) {
      const delButtons = document.querySelectorAll(
        `.form-list-delete.${castArray(name).join('_')}-delete`,
      );
      Array.prototype.forEach.call(delButtons, (node) => node.parentNode.removeChild(node));
    }
  }, [allowDelete, name]);

  const getContent = useCallback(
    (
      field: FormListFieldData,
      index: number,
      { fields, remove }: { fields: FormListFieldData[]; remove: FormListOperation['remove'] },
    ) => {
      const showRemove = fields.length > min;
      return (
        <Card
          className="form-list-content-card"
          key={field.key}
          loading={loading}
          bordered={!inline}
        >
          {showRemove ? (
            <Popconfirm
              title={`Are you sure you want to delete this ${title}?`}
              onConfirm={() => remove(index)}
              okButtonProps={{ danger: true }}
              okText="Delete"
              placement="topRight"
              getPopupContainer={(node) => node.closest('.ant-card') as HTMLElement}
              icon={<QuestionCircleOutlined className="icon-danger" />}
            >
              <Button
                className={classNames('form-list-delete', `${castArray(name).join('_')}-delete`)}
                danger
                type="primary"
                size={inline ? 'small' : undefined}
                icon={<CloseOutlined />}
                style={deleteBtnStyle}
              />
            </Popconfirm>
          ) : null}

          <fieldset>{render(field)}</fieldset>
        </Card>
      );
    },
    [inline, loading, min, name, render, title, deleteBtnStyle],
  );

  const getAddButton = useCallback(
    (fields: FormListFieldData[], add: FormListOperation['add']) => (
      <Button
        type="primary"
        size="middle"
        icon={<PlusOutlined />}
        onClick={() => {
          add(defaultValue);
          postAdd?.();
        }}
      >
        {`Add ${!fields.length ? startCase(pluralTitle) : 'More'}`}
      </Button>
    ),
    [defaultValue, pluralTitle, postAdd],
  );

  const initialValue = useMemo(() => {
    const val = [];
    if (min) {
      for (let index = 0; index < min; index++) {
        val.push(defaultValue);
      }
    }
    return val;
  }, [defaultValue, min]);

  return (
    <Form.List initialValue={initialValue} name={name}>
      {(fields, { add, remove }) => (
        <Card
          title={<InfoTooltip.Label label={startCase(pluralTitle)} tooltip={tooltip} />}
          loading={loading}
          size={inline ? 'small' : 'default'}
          className={`form-list ${inline ? 'inline-form-list' : 'block-form-list'}`}
          extra={!fields.length ? getAddButton(fields, add) : null}
          {...cardProps}
        >
          {!fields.length ? null : (
            <>
              {fields.map((field, index) => getContent(field, index, { fields, remove }))}
              {fields.length < max && allowAdd ? getAddButton(fields, add) : null}
            </>
          )}
        </Card>
      )}
    </Form.List>
  );
}

export default FormDynamicList;
