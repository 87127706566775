import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TooltipProps } from 'antd/lib/tooltip';

function getTooltipConfig(
  title?: string | JSX.Element,
): (TooltipProps & { icon: JSX.Element }) | null {
  if (!title) return null;
  return {
    icon: <InfoCircleOutlined className="info-tooltip-icon" style={{ marginLeft: 5 }} />,
    placement: 'right',
    title,
  };
}

function InfoTooltip(tooltip: string | JSX.Element) {
  if (!tooltip) return null;

  const tooltipConfig = getTooltipConfig?.(tooltip);
  return (
    <Tooltip title={tooltipConfig?.title} placement={tooltipConfig?.placement}>
      {tooltipConfig?.icon}
    </Tooltip>
  );
}

InfoTooltip.Label = ({
  label,
  tooltip,
}: {
  label: string;
  tooltip?: string | React.ReactElement;
}) => {
  if (!tooltip) return label ? <>{label}</> : null;
  return (
    <>
      <span>{label}</span>
      {InfoTooltip(tooltip)}
    </>
  );
};

InfoTooltip.Config = getTooltipConfig;

export default InfoTooltip;
