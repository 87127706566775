import React, { useCallback, useMemo } from 'react';
import { isNil } from 'lodash';
import { InputNumber, Form, FormItemProps, InputNumberProps } from 'antd';
import { transformRules, Rule } from '../utils/rules';
import InfoTooltip from './InfoToolTip';

interface FormInputNumberProps {
  name: FormItemProps['name'];
  label: string;
  tooltip?: PropPresets.Tooltip;
  placeholder?: string;
  type?: 'number' | 'integer' | 'float';
  percent?: boolean;
  disabled?: boolean;
  rules?: Rule[];
  inputProps?: InputNumberProps;
  formItemProps?: FormItemProps;
  initialValue?: FormItemProps['initialValue'];
}

function FormInputNumber(props: FormInputNumberProps) {
  const {
    name,
    label,
    placeholder,
    type = 'number',
    disabled = false,
    inputProps,
    formItemProps,
    rules: propRules,
    tooltip,
    percent = false,
    initialValue,
  } = props;

  const rules = useMemo(() => {
    const newRules = propRules ?? [];
    const maxRuleExists = newRules.some((r) => 'max' in r);
    if (!maxRuleExists) newRules.push({ max: 1000000000 });
    return transformRules(newRules, { type });
  }, [propRules, type]);

  const formatPercent: InputNumberProps['formatter'] = useCallback(
    (val) => (!isNil(val) ? `${val}%` : ''),
    [],
  );

  return (
    <Form.Item
      hasFeedback={!!rules.length}
      name={name}
      label={label}
      rules={rules}
      tooltip={InfoTooltip.Config(tooltip)}
      normalize={(val) => (!isNil(val) ? Number(val) : undefined)}
      initialValue={initialValue}
      validateFirst
      {...formItemProps}
    >
      <InputNumber
        placeholder={placeholder || label}
        disabled={disabled}
        style={{ width: '100%' }}
        formatter={percent ? formatPercent : undefined}
        {...inputProps}
      />
    </Form.Item>
  );
}

export default FormInputNumber;
