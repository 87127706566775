import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { isPlainObject, isNil, isEqual } from 'lodash';
import { Form, Select, FormItemProps, SelectProps as AntSelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Formatter } from '../utils';
import { transformRules, noWhitespaces, Rule, RuleType } from '../utils/rules';
import InfoTooltip from './InfoToolTip';

type SingleMode = 'default';
type MultipleMode = 'multiple' | 'tags';

export interface FormSelectProps {
  name: FormItemProps['name'];
  label: string;
  tooltip?: PropPresets.Tooltip;
  placeholder?: string;
  mode?: SingleMode | MultipleMode;
  disabled?: boolean;
  rules?: Rule[];
  options: PropPresets.Options;
  capitalizeOptions?: boolean;
  formItemProps?: FormItemProps;
  selectProps?: AntSelectProps<SelectValue>;
  initialValue?: number | string | number[] | string[];
}

function FormSelect(props: FormSelectProps) {
  const {
    label,
    name,
    mode: propMode = 'default',
    formItemProps,
    placeholder,
    disabled = false,
    selectProps,
    tooltip,
    initialValue,
    rules: propRules,
    options: propOptions,
    capitalizeOptions = true,
  } = props;

  const [options, setOptions] = useState<PropPresets.OptionObject[]>([]);

  const rules = useMemo(() => {
    let newRules = [...(propRules || [])];
    if (propMode === 'tags') newRules = [...newRules, noWhitespaces];

    let type: RuleType = 'array';
    if (propMode === 'default') {
      type = typeof (propOptions[0] || '') as RuleType;
      if (type === 'object') {
        type = typeof ((propOptions[0] as PropPresets.OptionObject).value || '') as RuleType;
      }
    }
    return transformRules(newRules, { type });
  }, [propMode, propRules, propOptions]);

  const isRequired = useMemo(() => rules.some((rule) => 'required' in rule), [rules]);

  const handleInputKeyDown = useCallback((e) => {
    // Enter (Don't delete existing value on double enter)
    if (e.keyCode === 13 && !e.target.value) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const getPopupContainer: AntSelectProps<SelectValue>['getPopupContainer'] = useCallback(
    (node) => node.closest('.ant-card') || node.closest('.ant-form-item'),
    [],
  );

  const mode: MultipleMode | undefined = propMode !== 'default' ? propMode : undefined;

  useEffect(() => {
    if (propOptions.length) {
      let formattedOps: PropPresets.OptionObject[];
      if (!isNil(propOptions[0]) && !isPlainObject(propOptions[0])) {
        formattedOps = Formatter.makeOptions(propOptions as string[] | number[], capitalizeOptions);
      }
      else {
        formattedOps = propOptions as PropPresets.OptionObject[];
      }
      if (!isEqual(options, formattedOps)) setOptions(formattedOps);
    }
  }, [propOptions, capitalizeOptions, options]);

  return (
    <Form.Item
      hasFeedback={!!rules.length}
      name={name}
      label={label}
      rules={rules}
      tooltip={InfoTooltip.Config(tooltip)}
      initialValue={initialValue}
      validateFirst
      {...formItemProps}
    >
      <Select
        allowClear={!isRequired}
        showSearch
        mode={mode}
        tokenSeparators={[',']}
        options={options as AntSelectProps<SelectValue>['options']}
        placeholder={placeholder || label}
        disabled={disabled}
        optionFilterProp="label"
        dropdownStyle={mode === 'tags' && !options.length ? { display: 'none' } : undefined}
        onInputKeyDown={mode === 'tags' ? handleInputKeyDown : undefined}
        getPopupContainer={getPopupContainer}
        {...selectProps}
      />
    </Form.Item>
  );
}

export default FormSelect;
