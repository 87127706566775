import { FormItemProps, TreeSelect, Form, Spin } from 'antd';
import { castArray, debounce, get, isNil, omit, uniqBy } from 'lodash';
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Variables } from '../api';
import { Rule } from '../utils/rules';
import InfoTooltip from './InfoToolTip';

type TreeDataOption = {
  id: string;
  value: string | number;
  pId: number;
  title: string;
  isLeaf?: boolean;
  selectable?: boolean;
  children?: TreeDataOption[];
};

export interface TreeSelectSearchProps {
  name: FormItemProps['name'];
  label: string;
  api: any;
  onLoadApi: any;
  tooltip?: PropPresets.Tooltip;
  type?: 'string' | 'number';
  placeholder?: string;
  disabled?: boolean;
  rules?: Rule[];
  mode?: 'default' | 'multiple';
  searchKey?: string;
  labelKey?: string;
  childLabelKey?: string;
  dataKey?: string;
  childDataKey?: string;
  formItemProps?: FormItemProps;
  initialValue?: number | string | number[] | string[];
  apiVariables?: Variables;
}

export default function TreeSelectSearch(props: TreeSelectSearchProps) {
  const {
    name,
    label,
    tooltip,
    api: useapi,
    onLoadApi,
    placeholder = label,
    mode: propMode = 'default',
    searchKey = 'ids',
    dataKey = 'data.result',
    childDataKey = 'data.result',
    childLabelKey = 'label',
    labelKey = 'label',
    rules: propRules,
    initialValue,
    formItemProps,
    apiVariables,
  } = props;

  const lastFetchId = useRef(0);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<TreeDataOption[]>([]);
  const [options, setOptions] = useState<TreeDataOption[]>([]);

  const allOptions = useMemo(
    () => uniqBy([...selectedOptions, ...options].filter(Boolean), (option) => option.value),
    [selectedOptions, options],
  );

  const fetchData = useCallback(
    (
      val: string | PlainObject<any> = '',
      apiVars?: PlainObject<any>,
    ): Promise<TreeDataOption[]> => {
      setLoading(true);

      let vars: PlainObject<any> = apiVars
        ? { limit: 20, ...apiVars }
        : { limit: 20, ...apiVariables };
      if (typeof val === 'string') vars.phrase = val;
      else vars = { ...vars, phrase: '', ...val };
      return new Promise((resolve, reject) => {
        useapi({ params: vars })
          .then((res: any) => {
            const data: TreeDataOption[] = get(res, dataKey).map((item: any) => ({
              id: item.value,
              pId: 0,
              title: item[labelKey],
              value: item.value,
              isLeaf: false,
            }));
            resolve(data);
            setLoading(false);
          })
          .catch(reject);
      });
    },
    [apiVariables, dataKey, labelKey, useapi],
  );

  const onLoadData = (treeNode: any) =>
    onLoadApi({ input: treeNode.id })
      .then((res: any) => {
        const data: TreeDataOption[] = get(res, childDataKey).map((item: any) => ({
          id: `${item.value}-${treeNode.id}`,
          pId: treeNode.id,
          title: item[childLabelKey],
          value: `${item.value}-${treeNode.id}`,
          isLeaf: true,
          selectable: false,
        }));
        setOptions((prevValues) => [...prevValues, ...data]);
      })
      .catch(() => {});

  const addOptions = useRef(
    debounce(async (phrase = '', calledFrom = 'onSearch') => {
      lastFetchId.current += 1;
      const fetchId = lastFetchId.current;

      try {
        const result = await fetchData(phrase);
        // Don't set for older calls
        if (fetchId !== lastFetchId.current) return;
        setOptions(result);
        if (calledFrom === 'onSearch') result.forEach((res) => onLoadData({ id: res.id }));
      }
      catch (err) {
        // logger.error({ err }, 'Form Search Error');
      }
    }, 500),
  ).current;

  const onSelect = useCallback(
    (val) => {
      const optionToAdd = options.find((option) => option.value === val);
      if (optionToAdd) {
        setSelectedOptions((prevVals) => {
          const newOptions = propMode === 'multiple' ? [...prevVals, optionToAdd] : [optionToAdd];
          // onSelectChange?.(newOptions);
          return newOptions;
        });
      }
    },
    [options, propMode],
  );

  const onDeselect = useCallback((val) => {
    setSelectedOptions((prevVals) => {
      const newOptions = prevVals.filter((option) => option.value !== val);
      // onSelectChange?.(newOptions);
      return newOptions;
    });
  }, []);

  // initialize options on focus
  const onFocus = useCallback(() => {
    if (!options.length) addOptions('', 'onFocus');
  }, [addOptions, options.length]);

  useEffect(() => {
    const initVals = castArray(initialValue || []);
    if (isNil(initVals) || !initVals.length) return;

    fetchData({ [searchKey]: initVals, limit: initVals.length }, omit(apiVariables, 'status')).then(
      (result) => {
        setSelectedOptions(result);
      },
    );
  }, [apiVariables, fetchData, initialValue, searchKey]);

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      rules={propRules}
      tooltip={InfoTooltip.Config(tooltip)}
      validateFirst
      initialValue={initialValue}
      {...formItemProps}
    >
      <TreeSelect
        showSearch
        treeDataSimpleMode
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder={placeholder}
        filterTreeNode
        loadData={onLoadData}
        onFocus={onFocus}
        multiple={propMode === 'multiple'}
        allowClear
        treeNodeFilterProp="title"
        onSearch={addOptions}
        onSelect={onSelect}
        onDeselect={onDeselect}
        notFoundContent={
          loading ? (
            <div style={{ textAlign: 'center', padding: '10px 0' }}>
              <Spin />
            </div>
          ) : undefined
        }
        treeData={allOptions}
      />
    </Form.Item>
  );
}
