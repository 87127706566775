/* eslint-disable import/no-extraneous-dependencies */
import { BaseRule } from 'rc-field-form/lib/interface';
import type {
  ValidatorRule,
  Rule,
  RuleType as _RuleType,
  RuleObject,
  ArrayRule,
} from 'rc-field-form/lib/interface';
import isAlpha from 'validator/lib/isAlpha';
import { castArray, countBy, isNil, isPlainObject } from 'lodash';
import { FormInstance } from 'antd';

type RuleType = _RuleType | 'array';
const requiredArray: ArrayRule = { required: true, type: 'array', min: 1 };

const required: BaseRule = { required: true, message: 'Field is required' };
const email: BaseRule = { type: 'email' };

const alpha: ValidatorRule = {
  validator: async (rule, val) => {
    if (isAlpha(val, 'en-US', { ignore: ' ' })) return true;
    throw new Error('Only Alphabets are allowed');
  },
};

const pattern = (val: RegExp): BaseRule => ({
  pattern: new RegExp(val),
  message: 'Does Not match Constraints',
});

const max = (val: number): BaseRule => ({ max: val, whitespace: true });
const min = (val: number): BaseRule => ({ min: val });

const decimalLength = (allowedDecimalLength = 5): ValidatorRule => ({
  validator: async (rule, val) => {
    if (!val) return;
    const valString = val.toString();
    const [, decimalPart] = valString.split('.');
    if (decimalPart && decimalPart.length > allowedDecimalLength) {
      throw new Error(`${allowedDecimalLength} decimal places are allowed at most.`);
    }
  },
});

function transformRules(
  rules: Rule[] = [],
  extra: PlainObject<string> & { type?: RuleType | 'array' },
) {
  const { type, ...rest } = extra;
  return rules.map((rule) => {
    if (!isPlainObject(rule)) return rule;
    rule = { ...rest, ...rule };
    if (type && (!isNil(rule.min) || !isNil(rule.max) || !isNil(rule.len))) {
      rule = { ...rule, type };
    }
    return rule;
  });
}

const equal =
  (field: any) =>
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator: async (rule: any, val: any) => {
        if (getFieldValue(field) === val) {
          return true;
        }
        throw new Error(`${rule.field} must be equal to ${field}`);
      },
    });

function getUniqEntityRule(form: FormInstance): ValidatorRule {
  return {
    validator: (rule, value: any, cb) => {
      const obj = form.getFieldValue('projectGroupIdSenderGroupId');
      if (countBy(obj, 'projectGroupId')[value] > 1) cb('Project Group Already Selected');
      cb();
    },
  };
}

const noWhitespace: BaseRule = { whitespace: true };
type GetOptionsFn = () => Promise<PropPresets.OptionObject[]>;
const getSearchRule = (getOptions: GetOptionsFn): ValidatorRule => ({
  validator: async (rule, value: any) => {
    if (value === undefined || (Array.isArray(value) && !value.length)) return true;

    const validOptions = (await getOptions()).map((o) => o.value);
    const currValues = castArray(value);

    const errors = currValues
      .map((val) => {
        if (validOptions.includes(val)) return false;
        return true;
      })
      .filter(Boolean);

    if (errors.length) throw new Error('Invalid ID or Item is Inactive');
    return true;
  },
});

/**
 * NOTE: It is important to have a default value of empty array if using an array rule
 * @see https://www.npmjs.com/package/async-validator#defaultfield
 */
function arrayOfRule(rule: RuleObject) {
  return {
    type: 'array' as const,
    options: { first: true },
    defaultField: rule,
  };
}
const noWhitespaces = arrayOfRule(noWhitespace);
export type { Rule, RuleType };

export {
  alpha,
  decimalLength,
  email,
  equal,
  getSearchRule,
  getUniqEntityRule,
  max,
  min,
  noWhitespace,
  required,
  requiredArray,
  transformRules,
  noWhitespaces,
  pattern,
};
