import React, { useCallback, useState, MouseEventHandler } from 'react';
import { Tooltip, TooltipProps } from 'antd';

type OverflowTooltipProps =
  | string
  | {
      title: string;
      children: (onMouseEnter: MouseEventHandler<HTMLElement>) => JSX.Element;
    };

function OverflowTooltip(props: OverflowTooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = useCallback<MouseEventHandler<HTMLElement>>((e) => {
    const target = e.target as HTMLElement;
    const parent =
      (target.parentNode as HTMLEmbedElement)?.type === 'button'
        ? (target.parentNode as HTMLElement)
        : target.closest('td') || target.closest('div');
    if (parent && parent.scrollWidth > parent.offsetWidth) {
      const timout = setTimeout(() => setShowTooltip(true), 500);
      target.onmouseleave = () => {
        clearTimeout(timout);
        setShowTooltip(false);
      };
    }
    else {
      setShowTooltip(false);
    }
  }, []);

  const getPopupContainer = useCallback<NonNullable<TooltipProps['getPopupContainer']>>(
    (node) => (node.closest('.ant-card') || node.closest('.ant-table')) as HTMLElement,
    [],
  );

  let title: string;
  let children: JSX.Element;

  if (!props) return <>-</>;
  if (typeof props === 'string') {
    title = props;
    children = <span onMouseEnter={handleMouseEnter}>{title}</span>;
  }
  else {
    title = props.title;
    children = props.children(handleMouseEnter);
  }

  if (typeof title !== 'string' || !showTooltip) return children;

  return (
    <Tooltip
      title={title}
      placement="bottomLeft"
      defaultVisible
      getPopupContainer={getPopupContainer}
    >
      {children}
    </Tooltip>
  );
}
export default OverflowTooltip;
