import { Button, Card, Col, message, Row, Typography } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from 'antd/lib/layout/layout';
import { FormInput, StatusBadge } from '../../components';
import { PASSWORD_VALIDATOR } from '../../constatnts/Enums';
import useApi from '../../api';
import { logout } from '../../store/actions/authAction';
import { equal, max, min, pattern, required } from '../../utils/rules';
import { ReactComponent as LogoSvg } from '../../icons/logo.svg';
import './styles.scoped.less';

export default function FirstResetPassword({ firstLogin }: { firstLogin: boolean }) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (val: any) => {
    setSubmitting(true);
    useApi.Auth.FirstResetPassword({ data: val }, { notification: true })
      .then(() => {
        message.info('Please login with your new password');
        dispatch(logout);
      })
      .catch(() => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Layout className="main">
      <Link to="/" className="reset-logo-link">
        <LogoSvg />
      </Link>

      <Row className="auth-page-row" justify="center" gutter={20}>
        <Col xs={24} sm={12} md={8}>
          <Card
            style={{
              marginTop: '2rem',
            }}
            className="reset-title"
            title={<Typography.Title level={3}>Reset Password</Typography.Title>}
          >
            {firstLogin ? (
              <Row justify="center" className="warning">
                <StatusBadge
                  val="It is recommended to reset your password on First time Login"
                  color="warning"
                />
              </Row>
            ) : null}
            <Form className="reset-password-form" layout="vertical" onFinish={handleSubmit}>
              <FormInput
                name="password"
                label="New Password"
                placeholder="Password"
                type="password"
                tooltip="Password length must be between 6 to 15. Contains 1 Upper Case, 1 Lower Case, and 1 number"
                rules={[required, pattern(PASSWORD_VALIDATOR), min(6), max(15)]}
              />

              <FormInput
                name="confirm-password"
                label="Confirm Password"
                type="password"
                formItemProps={{
                  dependencies: ['password'],
                }}
                rules={[required, pattern(PASSWORD_VALIDATOR), min(6), max(15), equal('password')]}
              />

              <Button block htmlType="submit" type="primary" size="large" loading={submitting}>
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
