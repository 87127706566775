import { FormInstance } from 'antd';
import { AxiosError, AxiosRequestConfig, CancelToken, Method } from 'axios';
import { defaultsDeep } from 'lodash';
import axios from './axios';
import { basicSuccessHandler, basicErrorHandler, setFormErrors } from './handlers';

type APIObject = {
  method: Method;
  url: (input: any) => string;
  params?: any;
  cacheable?: boolean | number;
  debug?: boolean;
} & Partial<Omit<import('axios').AxiosRequestConfig, 'url' | 'method' | 'params'>>;

export interface HookOptions {
  form?: FormInstance;
  notification?: string | boolean;
  successNotification?: string | boolean;
  errorNotitfication?: string | boolean;
}
export interface AxiosOpt {
  cancelToken?: CancelToken;
  notification?: boolean | string;
  successNotification?: boolean | string;
  errorNotification?: boolean | string;
  formRef?: FormInstance;
  onError?: (err: AxiosError) => void;
}
export type Variables = PlainObject<any>;
export type ExecuteFn = (variables?: Variables, altSuccessDescription?: string) => Promise<any>;
type UseAPIResultState = { loading: boolean; data: any; error: any | null };
export type UseAPI = (opts?: HookOptions) => [ExecuteFn, UseAPIResultState];

function getApiHook(apiObject: APIObject): any {
  return async (axiosOptions: AxiosRequestConfig & { input?: any } = {}, opts: AxiosOpt = {}) => {
    const { input } = axiosOptions;
    const {
      notification,
      successNotification = notification,
      errorNotification = notification,
      onError,
      formRef,
      cancelToken,
    } = opts;
    const { cacheable, url, method, params, debug, ...rest } = apiObject;
    if (params) {
      axiosOptions.params = defaultsDeep(axiosOptions.params || {}, params);
    }

    if (axiosOptions.data && localStorage.getItem('panelType') === '1'){
      axiosOptions.data.panelType = 1;
    }
    if(localStorage.getItem('panelType') === '1' && !(input) && !(axiosOptions.data)){
      axiosOptions.params.panelType = 1
    }
    /**
     * @type {import('axios').AxiosRequestConfig}
     */
    const finalOpts: AxiosRequestConfig = {
      ...rest,
      ...axiosOptions,
      url: url(input),
      method,
    };

    // if (process.env.NODE_ENV === 'development' && debug) {
    //   logger.debug('API Options:', finalOpts, opts);
    // }

    if (cancelToken) {
      finalOpts.cancelToken = cancelToken;
    }

    try {
      const response = await axios(finalOpts);
      if (successNotification) {
        basicSuccessHandler(response, { notification: successNotification });
      }
      return response.data;
    }
    catch (err: any) {
      if (onError) {
        onError(err);
      }
      if (formRef) {
        setFormErrors(err, formRef);
      }
      if (errorNotification) {
        basicErrorHandler(err, { notification: errorNotification });
      }
      // TODO: Could turn this off with an option for calls that don't require an error state
      // Rethrow to consume by API callers in Components
      throw err;
    }
  };
}

const useApi = {
  Auth: {
    Login: getApiHook({
      method: 'POST',
      url: () => '/auth/login',
    }),
    ForgotPassword: getApiHook({
      method: 'POST',
      url: () => '/auth/forgot-password',
    }),
    CheckResetToken: getApiHook({
      method: 'GET',
      url: (token) => `/auth/reset-password/${token}`,
    }),
    ResetPassword: getApiHook({
      method: 'POST',
      url: (token) => `/auth/reset-password/${token}`,
    }),
    FirstResetPassword: getApiHook({
      method: 'POST',
      url: () => `/auth/reset-password`,
    }),
  },

  Click: {
    List: getApiHook({
      method: 'GET',
      url: () => '/click-logs',
    }),
    ButtonList: getApiHook({
      method: 'GET',
      url: () => '/short-url/whatsapp-click-logs',
    }),
    whatsappList: getApiHook({
      method: 'GET',
      url: () => '/whatsapp-click-logs',
    }),
    HashCreate: getApiHook({
      method: 'POST',
      url: () => '/short-url/button-hash',
    }),
  },

  Channel: {
    Create: getApiHook({
      method: 'POST',
      url: () => '/channel',
    }),
    Get: getApiHook({
      method: 'GET',
      url: (id) => `/channel/${id}`,
    }),
    List: getApiHook({
      method: 'GET',
      url: () => '/channel',
    }),
    Update: getApiHook({
      method: 'PUT',
      url: (id) => `/channel/${id}`,
    }),
  },

  ProjectGroup: {
    Create: getApiHook({
      method: 'POST',
      url: () => '/project-group',
    }),
    Get: getApiHook({
      method: 'GET',
      url: (id) => `/project-group/${id}`,
    }),
    List: getApiHook({
      method: 'GET',
      url: () => '/project-group',
    }),
    Update: getApiHook({
      method: 'PUT',
      url: (id) => `/project-group/${id}`,
    }),
  },

  ApiKey: {
    Create: getApiHook({
      method: 'POST',
      url: () => '/api-key',
    }),
    Get: getApiHook({
      method: 'GET',
      url: (id) => `/api-key/${id}`,
    }),
    List: getApiHook({
      method: 'GET',
      url: () => '/api-key',
    }),
    Update: getApiHook({
      method: 'PUT',
      url: (id) => `/api-key/${id}`,
    }),
    UpdateKey: getApiHook({
      method: 'POST',
      url: (id) => `/api-key/update-key/${id}`,
    }),
  },
  Domain: {
    Create: getApiHook({
      method: 'POST',
      url: () => '/domain',
    }),
    Get: getApiHook({
      method: 'GET',
      url: (id) => `/domain/${id}`,
    }),
    List: getApiHook({
      method: 'GET',
      url: () => '/domain',
    }),
    Update: getApiHook({
      method: 'PUT',
      url: (id) => `/domain/${id}`,
    }),
  },
  Campaign: {
    Create: getApiHook({
      method: 'POST',
      url: () => '/campaign',
    }),
    Get: getApiHook({
      method: 'GET',
      url: (id) => `/campaign/${id}`,
    }),
    List: getApiHook({
      method: 'GET',
      url: () => '/campaign',
    }),
    Update: getApiHook({
      method: 'PUT',
      url: (id) => `/campaign/${id}`,
    }),
  },
  CampaignStats: {
    Get: getApiHook({
      method: 'GET',
      url: () => '/campaign-stats/dashboard',
    }),
  },
  WhatsAppCampaignStats: {
    Get: getApiHook({
      method: 'GET',
      url: () => '/campaign-stats-whatsapp/dashboard',
    }),
  },
  Sender: {
    Create: getApiHook({
      method: 'POST',
      url: () => '/sender',
    }),
    Get: getApiHook({
      method: 'GET',
      url: (id) => `/sender/${id}`,
    }),
    GetSenderGroupSenders: getApiHook({
      method: 'GET',
      url: (id) => `/sender/sender-group/${id}`,
    }),
    List: getApiHook({
      method: 'GET',
      url: () => '/sender',
    }),
    Update: getApiHook({
      method: 'PUT',
      url: (id) => `/sender/${id}`,
    }),
  },
  User: {
    Create: getApiHook({
      method: 'POST',
      url: () => '/user',
    }),
    Get: getApiHook({
      method: 'GET',
      url: (id) => `/user/${id}`,
    }),
    List: getApiHook({
      method: 'GET',
      url: () => '/user',
    }),
    Update: getApiHook({
      method: 'PUT',
      url: (id) => `/user/${id}`,
    }),
  },
  SenderGroup: {
    Create: getApiHook({
      method: 'POST',
      url: () => '/sender-group',
    }),
    Get: getApiHook({
      method: 'GET',
      url: (id) => `/sender-group/${id}`,
    }),
    List: getApiHook({
      method: 'GET',
      url: () => '/sender-group',
    }),
    Update: getApiHook({
      method: 'PUT',
      url: (id) => `/sender-group/${id}`,
    }),
  },
  SentMessage: {
    List: getApiHook({
      method: 'GET',
      url: () => '/sent-message',
    }),
    WhatsappList: getApiHook({
      method: 'GET',
      url: () => '/whatsapp-sent-message',
    }),
  },
};

export default useApi;
