import { faChevronDown, faPowerOff, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Menu, Switch } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../store';
import { logout } from '../../store/actions/authAction';
import { FaIcon } from '../../components';

function Navbar() {
  const dispatch = useDispatch();
  // @ts-ignore
  const userName = useSelector((state) => state.auth.userName);
  // const [disabled, setDisabled] = useState(false);
  const { auth }: { auth: any } = store.getState();
  // useEffect(() => {
  // if (auth.role === UserRoleEnum.SUPERADMIN) {
  //   setDisabled(true);
  // }
  // else {
  //   setDisabled(false);
  // }
  // })
  const signOut = () => {
    localStorage.removeItem('panelType');
    dispatch(logout);
  };
  const initialCheckedState = localStorage.getItem('panelType') === '1';
  const buttonStyle = {
    backgroundColor: localStorage.getItem('panelType') === '1' ? 'green' : '',
  };
  const onChange = (checked: boolean) => {
    window.location.href = `${window.location.origin}/dashboard`
    // window.location.reload();
    let panelType = '0';
    if (checked) {
      panelType = '1';
      // initialCheckedState = false;
    }
    localStorage.setItem('panelType', panelType);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={signOut}>
        <FaIcon icon={faPowerOff} size="lg" />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  const getPopupContainer = useCallback(() => document.querySelector('.navbar'), []);

  return (
    <>
      <div />
      <Switch
        checkedChildren="WHATSAPIFY"
        unCheckedChildren="TEXTIFY"
        disabled={auth.role !== "SUPERADMIN"}
        defaultChecked={initialCheckedState}
        style={buttonStyle}
        onChange={onChange}
      />
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        overlayClassName="navbar-overlay"
        trigger={['click']}
        getPopupContainer={getPopupContainer}
      >
        <div>
          <FaIcon icon={faUserCircle} size="lg" />
          <span> </span>
          <span>{userName}</span>
          <span> </span>
          <FaIcon icon={faChevronDown} />
        </div>
      </Dropdown>
    </>
  );
}

export default Navbar;
