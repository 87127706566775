import React, { useCallback } from 'react';
import { Form, Checkbox, FormItemProps, CheckboxProps as AntCheckboxProps } from 'antd';
import { Rule } from '../utils/rules';
import InfoTooltip from './InfoToolTip';

interface FormCheckboxProps {
  name: FormItemProps['name'];
  label: string;
  tooltip?: PropPresets.Tooltip;
  disabled?: boolean;
  rules?: Rule[];
  checkboxProps?: AntCheckboxProps;
  formItemProps?: FormItemProps;
  onCheckChange?: (checked: boolean) => void;
  initialChecked?: boolean;
}

function FormCheckbox(props: FormCheckboxProps) {
  const {
    name,
    label,
    disabled = false,
    checkboxProps,
    formItemProps,
    onCheckChange,
    tooltip,
    initialChecked = false,
    rules: propRules,
  } = props;

  const handleChange = useCallback((e) => onCheckChange?.(e.target.checked), [onCheckChange]);

  return (
    <Form.Item
      name={name}
      rules={propRules}
      valuePropName="checked"
      initialValue={initialChecked}
      validateFirst
      {...formItemProps}
    >
      <Checkbox disabled={disabled} onChange={handleChange} {...checkboxProps}>
        <InfoTooltip.Label {...{ label, tooltip }} />
      </Checkbox>
    </Form.Item>
  );
}

export default FormCheckbox;
