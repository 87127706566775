import { lazy, useReducer, useCallback, useEffect, LazyExoticComponent } from 'react';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';

export type DrawerTypes =
  | 'APIKey'
  | 'Channels'
  | 'ProjectGroup'
  | 'Domain'
  | 'SenderID'
  | 'User'
  | 'HashCreate'
  | 'SenderGroup';

export type DrawerChild = {
  component: LazyExoticComponent<(props: DrawerChildComponentProps) => JSX.Element>;
  buttons?: TitleButtonType[];
  title?: string | JSX.Element;
};

export const drawerMap: Record<DrawerTypes, DrawerChild> = {
  APIKey: {
    component: lazy(
      () => import(/* webpackChunkName: "AddApiKey" */ '../views/apps/ApiKey/AddApiKey'),
    ),
  },
  Channels: {
    component: lazy(
      () => import(/* webpackChunkName: "AddChannels" */ '../views/apps/Channel/AddChannel'),
    ),
  },
  Domain: {
    component: lazy(
      () => import(/* webpackChunkName: "AddDomain" */ '../views/apps/Domain/AddDomain'),
    ),
  },
  ProjectGroup: {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "AddProjectGroup" */ '../views/apps/ProjectGroup/AddProjectGroup'
        ),
    ),
  },
  SenderID: {
    component: lazy(
      () => import(/* webpackChunkName: "AddSender" */ '../views/apps/Sender/AddSender'),
    ),
  },
  SenderGroup: {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "AddSenderGroup" */ '../views/apps/SenderGroup/AddSenderGroup'
        ),
    ),
  },
  User: {
    component: lazy(() => import(/* webpackChunkName: "AddUser" */ '../views/apps/User/AddUser')),
  },
  HashCreate: {
    component: lazy(() => import(/* webpackChunkName: "AddUser" */ '../views/apps/ButtonClickLogs/AddHashKey')),
  },
};

export const queryParams = {
  drawers: withDefault(ArrayParam, [], true),
  drawerIds: withDefault(ArrayParam, [], true),
};

export interface DrawerData {
  type: DrawerTypes;
  id?: string;
  visible: boolean;
  submitted?: boolean;
  data?: any;
  title?: string | JSX.Element;
}

type DrawerAction =
  | {
      type: 'OPEN_DRAWER';
      data: Omit<DrawerData, 'visible' | 'submitted'>;
    }
  | {
      type: 'HIDE_DRAWER';
      data: Pick<DrawerData, 'submitted'>;
    }
  | {
      type: 'CLOSE_DRAWER' | 'CLOSE_ALL_DRAWER';
    };

function drawerReducer(oldDrawers: DrawerData[], action: DrawerAction) {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return [
        ...oldDrawers,
        { type: action.data.type, id: action.data.id, data: action.data.data, visible: true },
      ];
    case 'CLOSE_DRAWER':
      return oldDrawers.slice(0, -1);
    case 'CLOSE_ALL_DRAWER':
      return [];
    case 'HIDE_DRAWER': {
      if (!oldDrawers.length) return oldDrawers;
      const newDrawers = [...oldDrawers];
      Object.assign(newDrawers[newDrawers.length - 1], {
        visible: false,
        submitted: action.data.submitted,
      });
      return newDrawers;
    }
    default:
      throw new Error('Invalid drawer action');
  }
}

export type UseDrawerQuery = UseQP<typeof queryParams>;

function initDrawersFromQuery({ drawers, drawerIds }: UseDrawerQuery['query']): DrawerData[] {
  const drawerData: DrawerData[] = [];
  drawers.forEach((drawer, index) => {
    if (!drawer) return;
    drawerData.push({
      type: drawer as DrawerTypes,
      id: drawerIds[index] || '',
      visible: true,
    });
  });
  return drawerData;
}

export type OpenDrawerFn = (type: DrawerTypes, id?: string, data?: any) => void;

export type CloseDrawerFn = (submitted?: boolean) => void;

export function useRightDrawer() {
  const [query, setQuery] = useQueryParams(queryParams);
  const [drawers, dispatch] = useReducer(drawerReducer, query, initDrawersFromQuery);
  const openDrawer = useCallback<OpenDrawerFn>(
    (type, id, data) => {
      setQuery(
        (prevQuery) => ({
          drawers: [...(prevQuery.drawers ?? []), type],
          drawerIds: [...(prevQuery.drawerIds ?? []), id ?? ''],
        }),
        'pushIn',
      );
      dispatch({ type: 'OPEN_DRAWER', data: { id, type, data } });
    },
    [setQuery],
  );

  const closeAllDrawer = useCallback(() => {
    setQuery({ drawers: [], drawerIds: [] }, 'pushIn');
    dispatch({ type: 'CLOSE_ALL_DRAWER' });
  }, [setQuery]);

  const closeDrawer = useCallback<CloseDrawerFn>((submitted = false) => {
    // First hide and then remove in effect
    dispatch({ type: 'HIDE_DRAWER', data: { submitted } });
  }, []);

  useEffect(() => {
    if (drawers.length && !drawers[drawers.length - 1].visible) {
      setTimeout(() => {
        setQuery(
          (prevQuery) => ({
            drawers: prevQuery.drawers.slice(0, -1),
            drawerIds: prevQuery.drawerIds.slice(0, -1),
          }),
          'pushIn',
        );
        dispatch({ type: 'CLOSE_DRAWER' });
      }, 300);
    }
  }, [drawers, setQuery]);

  return {
    drawers,
    openDrawer,
    closeDrawer,
    closeAllDrawer,
  };
}
