import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { entries, get, isEmpty } from 'lodash';
import { Dayjs } from 'dayjs';
import { Form, Button, Row, Col, Card, CardProps, Space, Spin, FormProps, notification } from 'antd';
import { CheckOutlined, ReloadOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { DateParam, JsonParam, StringParam, useQueryParams } from 'use-query-params';
import { convertToQP, convertFromQP } from './utils';
import filterTypes, { FiltersMap } from './types';

const { protocol } = window.location;
const BaseUrl = process.env.REACT_APP_BASE_API;

export interface FiltersProps {
  loading?: boolean;
  autoSubmit?: boolean;
  updateQuery?: boolean;
  tableData?: any;
  filtersMap: FiltersMap;
  onValidSubmit: (apiVariables: PlainObject<any>) => void;
  onReset?: () => void;
  cardProps?: CardProps;
  showDownload?: boolean;
}

export const queryParams = {
  fromDate: DateParam,
  toDate: DateParam,
  phrase: StringParam,
  filters: JsonParam,
};

export type UseFiltersQuery = UseQP<typeof queryParams>;
export type FiltersFormData = {
  dateRange?: [Dayjs, Dayjs];
  phrase?: string;
  filters?: PlainObject<unknown>;
};

function Filters(props: FiltersProps) {
  const {
    loading = false,
    autoSubmit = true,
    updateQuery = true,
    filtersMap,
    onValidSubmit,
    onReset,
    cardProps,
    showDownload,
    tableData,
  } = props;

  const [form] = Form.useForm();
  const location = useLocation<{ autoSubmit?: boolean }>();
  const autoSubmitted = useRef(false);
  const [query, setQuery] = useQueryParams(queryParams);

  const getInitialValues = useCallback(
    (newQuery: UseFiltersQuery['query']) => convertFromQP(newQuery, filtersMap),
    [filtersMap],
  );

  const [initialValues, setInitialValues] = useState(() => getInitialValues(query));
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if ((autoSubmit || location.state.autoSubmit) && !autoSubmitted.current) {
      autoSubmitted.current = true;
      form.submit();
    }
  }, [autoSubmit, location, autoSubmitted, form]);

  const handleReset = useCallback(
    ({ fromHistory = false } = {}) => {
      setInitialValues(
        getInitialValues(
          fromHistory ? query : { filters: {}, phrase: null, toDate: null, fromDate: null },
        ),
      );

      if (fromHistory) autoSubmitted.current = true;
      form.resetFields();
      if (onReset && !fromHistory) onReset();
      form.submit();
    },
    [getInitialValues, query, form, onReset],
  );

  const handleSubmit = useCallback<NonNullable<FormProps['onFinish']>>(
    (data) => {
      const { apiVariables } = convertToQP(setQuery, data, query, {
        updateQuery,
        defaults: autoSubmitted.current,
      });
      setFormData(apiVariables);
      onValidSubmit(apiVariables);
    },
    [query, setQuery, updateQuery, onValidSubmit],
  );

  // Submit failed
  const handleFinishFailed = useCallback<NonNullable<FormProps['onFinishFailed']>>(
    ({ errorFields }) => {
      if (!errorFields[0]?.name) return;
      form.scrollToField(errorFields[0].name);
    },
    [form],
  );

  const FilterItems = useMemo(
    () =>
      entries(filterTypes).map(([type, { component: render }], index) => {
        let filterProps = filtersMap[type as keyof FiltersMap];
        if (filterProps === true || (type === 'date' && filterProps === undefined)) {
          filterProps = {};
        }
        if (!filterProps) return null;

        const initialValKey =
          type === 'date'
            ? 'dateRange'
            : ['filters', 'name' in filterProps && filterProps.name ? filterProps.name : type];
        filterProps.initialValue = get(initialValues, initialValKey);

        // @ts-ignore
        return render({ ...filterProps, key: String(index) });
      }),
    [filtersMap, initialValues],
  );
  const buttonStyle = {
    backgroundColor: localStorage.getItem('panelType') === '1' ? 'green' : '',
  };
  const downloadButtonStyle = {
    backgroundColor: 'green',
  };
  if (!initialValues) return null;

  function buildQueryString(obj: { [x: string]: any; fromDate?: string | Date; toDate?: string | Date; filters?: { projectGroupId: string; bot: string; } | { [key: string]: any; }; }) {
    let queryString = '';
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (typeof value === 'object') {
        queryString += `${key}=${encodeURIComponent(JSON.stringify(value))}&`;
      }
      else {
        queryString += `${key}=${encodeURIComponent(value)}&`;
      }
    });
    queryString = queryString.slice(0, -1);
    return queryString;
  }

  const downloadClickLogs = () => {
    if (!isEmpty(tableData)) {
      if ('fromDate' in formData && 'toDate' in formData) {
        const { fromDate, toDate, ...filters } = formData as {
          fromDate: Date;
          toDate: Date;
          [key: string]: any;
        };
        const queryData = {
          fromDate,
          toDate,
          filters,
        };
        const queryString = buildQueryString(queryData);
        const downloadUrl = `${protocol}//${BaseUrl}/export-csv?${queryString}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.target = '_blank';
        downloadLink.setAttribute('download', 'export-csv.csv');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
    else {
      notification.error({
        message: `Error`,
        description: 'No data available to export!',
      });
    }
  };
  return (
    <Card className="filters" {...cardProps}>
      <Form
        form={form}
        className="filter-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleFinishFailed}
        initialValues={initialValues}
      >
        <Row gutter={20}>{FilterItems}</Row>

        <Row>
          <Col span={24}>
            <Space size="middle">
              <Button
                type="primary"
                htmlType="reset"
                danger
                size="large"
                icon={<ReloadOutlined />}
                disabled={loading}
                onClick={handleReset}
              >
                Reset
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                size="large"
                icon={<CheckOutlined />}
                disabled={loading}
                style={buttonStyle}
              >
                View
              </Button>
              {showDownload ? (
                <Button
                  type="primary"
                  size="large"
                  icon={<CloudDownloadOutlined />}
                  disabled={loading}
                  style={downloadButtonStyle}
                  onClick={downloadClickLogs}
                >
                  Download
                </Button>
              ) : null}

              {loading ? <Spin /> : null}
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default Filters;
