import React from 'react';
import { camelCase, get, startCase } from 'lodash';
import { Col } from 'antd';
import {
  CampaignType,
  ChannelServiceProvider,
  ChannelType,
  SentMessageType,
  Status,
  BotStatus,
  UserRole,
} from '../../constatnts/Enums';
import FormDate, { FormRangePickerProps } from '../FormDate';
import { required as requiredRule } from '../../utils/rules';
import FormInput from '../FormInput';
import FormSearch, { FormSearchProps } from '../FormSearch';
import FormSelect, { FormSelectProps } from '../FormSelect';
import UseApi from '../../api';

type SeachOpts = Partial<Omit<FormSearchProps, 'name' | 'api' | 'label'>>;

type SelectOpts = Partial<Omit<FormSelectProps, 'name' | 'label'>>;

function getIdSearchComponent(labelKey: string, searchProps: SeachOpts & { api?: string } = {}) {
  const defaultName = `${camelCase(labelKey)}Id`;
  const defaultLabel = startCase(labelKey);
  const {
    api = `${labelKey}.List`,
    tooltip = `Search by ${defaultLabel} Name`,
    ...restSearchProps
  } = searchProps;

  const useApi = get(UseApi, api);
  if (!useApi) throw new Error('Invalid search api');

  return (
    props: {
      name?: string;
      label?: string;
      key: string;
    } & SeachOpts,
  ) => {
    const { name = defaultName, label = defaultLabel, key, ...rest } = props;

    return (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormSearch
          name={['filters', name]}
          label={`${label} Filter`}
          placeholder={`Select ${label}`}
          tooltip={label === defaultLabel ? tooltip : `Search by ${label} name or ID`}
          api={useApi}
          {...restSearchProps}
          {...rest}
        />
      </Col>
    );
  };
}

function getCampaignSearchComponent(labelKey: string, searchProps: SeachOpts & { api?: string } = {}) {
  const defaultName = `${camelCase(labelKey)}`;
  const defaultLabel = startCase(labelKey);
  const {
    api = `${labelKey}.List`,
    ...restSearchProps
  } = searchProps;

  const useApi = get(UseApi, api);
  if (!useApi) throw new Error('Invalid search api');

  return (
    props: {
      name?: string;
      label?: string;
      key: string;
    } & SeachOpts,
  ) => {
    const { name = defaultName, label = defaultLabel, key, ...rest } = props;

    return (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormSearch
          name={['filters', name]}
          label={`${label} Search`}
          mode="multiple"
          tooltip="Search by any phrase"
          placeholder="Search by any phrase"
          api={useApi}
          {...restSearchProps}
          {...rest}
        />
      </Col>
    );
  };
}

function getTypeSelectComponent(defaultName: string, selectProps: SelectOpts = {}) {
  const { options: defaultOpts = [] } = selectProps;
  return (
    props: {
      name?: string;
      label?: string;
      key: string;
    } & SelectOpts,
  ) => {
    const {
      name = defaultName,
      label = startCase(name),
      options = defaultOpts,
      key,
      ...rest
    } = props;

    return (
      <Col xs={24} sm={12} lg={8} key={key}>
        <FormSelect
          name={['filters', name]}
          label={label}
          placeholder={`Select ${label}`}
          options={options}
          {...selectProps}
          {...rest}
        />
      </Col>
    );
  };
}

function getBotTypeSelectComponent(defaultName: string, selectProps: SelectOpts = {}) {
  const { options: defaultOpts = [] } = selectProps;
  return (
    props: {
      name?: string;
      label?: string;
      key: string;
    } & SelectOpts,
  ) => {
    const {
      name = defaultName,
      label = startCase(name),
      options = defaultOpts,
      key,
      ...rest
    } = props;

    return (
      <Col xs={24} sm={12} lg={8} key={key}>
        <FormSelect
          name={['filters', name]}
          label='Bot Click'
          placeholder={`Select ${label}`}
          options={options}
          {...selectProps}
          {...rest}
        />
      </Col>
    );
  };
}

const filterTypes = {
  date: {
    component: ({
      key,
      required = false,
      initialValue,
    }: {
      key: string;
      required?: boolean;
      initialValue?: FormRangePickerProps['initialValue'];
    }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormDate
          rules={required ? [requiredRule] : []}
          name="dateRange"
          label="From Date → To Date"
          type="range"
          initialValue={required ? initialValue : undefined}
        />
      </Col>
    ),
  },
  phrase: {
    component: ({
      label,
      key,
      initialValue,
    }: {
      label: string;
      key: string;
      initialValue?: string;
    }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormInput
          name="phrase"
          label={`${startCase(label)} Search`}
          tooltip="Search by any phrase"
          placeholder="Search by any phrase"
          formItemProps={{ initialValue }}
        />
      </Col>
    ),
  },
  campaignSearch: {
    default: [],
    component: getCampaignSearchComponent('Campaign', { api: 'Campaign.List' }),
  },
  projectGroupId: {
    default: [],
    component: getIdSearchComponent('ProjectGroup'),
  },
  senderId: {
    default: [],
    component: getIdSearchComponent('Sender ID', { api: 'Sender.List' }),
  },
  status: {
    default: 'ACTIVE',
    component: getTypeSelectComponent('status', { options: Status }),
  },
  bot: {
    default: 'Yes',
    component: getBotTypeSelectComponent('bot', { options: BotStatus }),
  },
  channelType: {
    component: getTypeSelectComponent('type', { options: ChannelType }),
  },
  serviceProvider: {
    component: getTypeSelectComponent('serviceProvider', { options: ChannelServiceProvider }),
  },
  userRole: {
    component: getTypeSelectComponent('role', { options: UserRole }),
  },
  sentMessageType: {
    component: getTypeSelectComponent('type', { options: SentMessageType }),
  },
  campaignType: {
    component: getTypeSelectComponent('type', { options: CampaignType }),
  },
  // panelType: localStorage.getItem('panelType')
};

type FilterTypes = typeof filterTypes;

export type FilterItemProps<T extends keyof FilterTypes> = Omit<
  Parameters<FilterTypes[T]['component']>[0],
  'key'
>;

type DateFilterProps = FilterItemProps<'date'> & { oldFromDate?: boolean };

export type FiltersMap = Partial<{
  [T in keyof FilterTypes]: T extends 'phrase'
    ? FilterItemProps<T>
    : boolean | (T extends 'date' ? DateFilterProps : FilterItemProps<T>);
}>;

export default filterTypes;
