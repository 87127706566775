import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FirstResetPassword from './views/auth/FirstResetPassword';
import Auth from './views/auth';
import Main from './views/main';

function App() {
  const { isLoggedIn, firstLogin } = useSelector((state) => ({
    // @ts-ignore
    isLoggedIn: Boolean(state.auth.accessToken),
    // @ts-ignore
    firstLogin: state.auth.firstLogin,
  }));
  if (!isLoggedIn) return <Auth />;
  if (firstLogin) return <FirstResetPassword firstLogin />;

  return (
    <>
      <Route key="login" exact path={['/login', '/']} render={() => <Redirect to="/dashboard" />} />

      <Main />
    </>
  );
}

export default App;
