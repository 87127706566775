import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons';

export interface FaIconProps extends FontAwesomeIconProps {
  iconStyle?: React.CSSProperties;
}

export default ({ iconStyle, ...rest }: FaIconProps) => (
  <Icon
    className="fa-icon"
    component={() => <FontAwesomeIcon {...rest} />}
    style={{ verticalAlign: 0, ...iconStyle }}
  />
);
